<template>
  <div class="max-w-xl mx-auto p-6 rounded-lg bg-[#F1FCF3]">
    <h1 class="font-bold font-poppins md:text-xl md:leading-4 md:mb-5">
      Price Details
    </h1>
    <h2 class="md:mb-5 font-bold font-poppins md:text-base md:leading-4">
      Bill Summary
    </h2>

    <div
      class="grid grid-cols-2 gap-y-2 font-poppins md:text-base font-normal leading-4"
    >
      <div class="">Item Total</div>
      <div class="text-right font-medium">
        ${{ totalPrice ? totalPrice.toFixed(2) : "0.00" }}
      </div>

      <div class="text-gray-600">GST</div>
      <div class="text-right font-medium">${{ gst.toFixed(2) }}</div>

      <div class="text-gray-600">Delivery Fee</div>
      <div class="text-right font-medium">${{ deliveryFee.toFixed(2) }}</div>
    </div>

    <hr class="my-4" />

    <div class="grid grid-cols-2 font-normal leading-4">
      <div class="text-[42001A] font-poppins">To Pay</div>
      <div class="text-right">
        ${{ totalToPay ? totalToPay.toFixed(2) : "0.00" }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PriceDetail",
  props: {
    totalPrice: {
      type: Number,
      required: true,
    },
  },
  computed: {
    gst() {
      return this.totalPrice ? this.totalPrice * 0.1 : 0;
    },
    deliveryFee() {
      return 5;
    },
    totalToPay() {
      return this.totalPrice + this.gst + this.deliveryFee;
    },
  },
};
</script>
