import BlogImage1 from "@/assets/BlogImg.png"; // Main image
import BlogImage4 from "@/assets/pd1.png"; // Main image
import BlogImage3 from "@/assets/pd2.png"; // Main image
import BlogImage5 from "@/assets/pd3.png"; // Main image
import BlogImage2 from "@/assets/slideOne.png"; // Secondary image for demo purposes
import img3 from "@/assets/customerImg.png";

export const blogs = [
  {
    readingTime: "2 min read",
    author: img3,
    authorName: "Andrea Wise",

    image: BlogImage4,
    secondaryImage: BlogImage4,
    category: "Women's Health",
    title: "Bye-Bye Leaks, Hello Freedom: The Right  ",
    datePosted: "October 15, 2024",
    shortDesc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    blogContent: `
   As a mother, it is essential to have open and honest conversations with your daughter about her menstrual cycle. Menstrual bleeding is a natural and normal process that every woman experiences, and it’s crucial to provide your daughter with the right information and support as she navigates this aspect of her life. 

Here are five important things you should tell your daughter about menstrual bleeding:

What is Considered Normal Menstrual Bleeding?
A normal range for menstrual bleeding typically involves a cycle length of 21 to 35 days. During this time, bleeding usually lasts for 2 to 7 days and should not exceed 30 ml of blood. Normal bleeding should not be associated with clots or severe pain that disrupts daily life. If her menstrual cycle falls within these parameters, she can consider it normal.

Recognizing Abnormal Menstrual Bleeding Patterns:
It’s essential for your daughter to know the signs of abnormal menstrual bleeding. If her cycle lasts less than 21 days or more than 35 days, or if she experiences bleeding for less than 2 days or more than 7 days, it might be a cause for concern. Additionally, if she notices clots or experiences severe pain during her periods that affect her daily activities, it is considered abnormal. In such cases, it’s advisable for her to consult a gynecologist to rule out any underlying health issues.

Common Causes of Heavy or Prolonged Menstrual Bleeding:
You should educate your daughter about some common causes of heavy or prolonged menstrual bleeding. These include ovarian causes like simple or complex ovarian cysts, polycystic ovarian syndrome (PCOS), and hormonal disorders. Uterine causes may involve fibroids, adenomyosis, endometrial hyperplasia, and in rare cases, cancer of the endometrium. Cervical causes such as polyps, fibroids, or ectopic growths can also contribute to abnormal bleeding. Additionally, systemic issues like coagulation abnormalities, liver or kidney disorders, hypo or hyperthyroidism, and obesity can impact menstrual bleeding.
The Impact of Hormonal Changes on Menstrual Bleeding:
Hormonal imbalances can significantly affect menstrual bleeding patterns. Lifestyle factors such as a sedentary lifestyle and improper eating habits can contribute to hormonal disturbances. If your daughter experiences irregular periods or notices symptoms like excessive hair growth on her face and chin, hair fall, difficulty losing weight, or sudden weight gain, it’s crucial for her to seek medical evaluation with a gynecologist. Addressing hormonal imbalances early on can prevent potential long-term adverse effects on her health.

Supporting Your Daughter in Tracking Her Menstrual Cycle:
As a mother, you can play an essential role in supporting your daughter in tracking her menstrual cycle. Encourage her to journal her period dates, just like she would with her other emotions. This journaling can be done using a period tracking app or maintaining a diary. By openly discussing her periods and any irregularities she may notice, you will help her understand the significance of regular periods and how to maintain them. If she ever feels uncomfortable or faces any challenges with her menstrual cycle, assure her that you are there to support and guide her.

In conclusion, understanding menstrual bleeding is vital for young girls as they transition into womanhood. By providing them with accurate information and a warm and friendly environment to discuss their menstrual health, we can empower them to make informed decisions about their well-being. Remember, being there for your daughter during this significant phase of her life will strengthen your bond and lay the foundation for open communication in the future.
    `,
  },
  {
    readingTime: "2 min read",
    author: img3,
    authorName: "Andrea Wise",

    image: BlogImage1, // Main image
    secondaryImage: BlogImage3, // Additional image
    category: "Women's Health",
    title: "Bye-Bye Leaks, Hello Freedom: The Right ",
    datePosted: "September 28, 2024",
    shortDesc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    blogContent: `
   As a mother, it is essential to have open and honest conversations with your daughter about her menstrual cycle. Menstrual bleeding is a natural and normal process that every woman experiences, and it’s crucial to provide your daughter with the right information and support as she navigates this aspect of her life. 

Here are five important things you should tell your daughter about menstrual bleeding:

What is Considered Normal Menstrual Bleeding?
A normal range for menstrual bleeding typically involves a cycle length of 21 to 35 days. During this time, bleeding usually lasts for 2 to 7 days and should not exceed 30 ml of blood. Normal bleeding should not be associated with clots or severe pain that disrupts daily life. If her menstrual cycle falls within these parameters, she can consider it normal.

Recognizing Abnormal Menstrual Bleeding Patterns:
It’s essential for your daughter to know the signs of abnormal menstrual bleeding. If her cycle lasts less than 21 days or more than 35 days, or if she experiences bleeding for less than 2 days or more than 7 days, it might be a cause for concern. Additionally, if she notices clots or experiences severe pain during her periods that affect her daily activities, it is considered abnormal. In such cases, it’s advisable for her to consult a gynecologist to rule out any underlying health issues.

Common Causes of Heavy or Prolonged Menstrual Bleeding:
You should educate your daughter about some common causes of heavy or prolonged menstrual bleeding. These include ovarian causes like simple or complex ovarian cysts, polycystic ovarian syndrome (PCOS), and hormonal disorders. Uterine causes may involve fibroids, adenomyosis, endometrial hyperplasia, and in rare cases, cancer of the endometrium. Cervical causes such as polyps, fibroids, or ectopic growths can also contribute to abnormal bleeding. Additionally, systemic issues like coagulation abnormalities, liver or kidney disorders, hypo or hyperthyroidism, and obesity can impact menstrual bleeding.
The Impact of Hormonal Changes on Menstrual Bleeding:
Hormonal imbalances can significantly affect menstrual bleeding patterns. Lifestyle factors such as a sedentary lifestyle and improper eating habits can contribute to hormonal disturbances. If your daughter experiences irregular periods or notices symptoms like excessive hair growth on her face and chin, hair fall, difficulty losing weight, or sudden weight gain, it’s crucial for her to seek medical evaluation with a gynecologist. Addressing hormonal imbalances early on can prevent potential long-term adverse effects on her health.

Supporting Your Daughter in Tracking Her Menstrual Cycle:
As a mother, you can play an essential role in supporting your daughter in tracking her menstrual cycle. Encourage her to journal her period dates, just like she would with her other emotions. This journaling can be done using a period tracking app or maintaining a diary. By openly discussing her periods and any irregularities she may notice, you will help her understand the significance of regular periods and how to maintain them. If she ever feels uncomfortable or faces any challenges with her menstrual cycle, assure her that you are there to support and guide her.

In conclusion, understanding menstrual bleeding is vital for young girls as they transition into womanhood. By providing them with accurate information and a warm and friendly environment to discuss their menstrual health, we can empower them to make informed decisions about their well-being. Remember, being there for your daughter during this significant phase of her life will strengthen your bond and lay the foundation for open communication in the future.
    `,
  },
  {
    readingTime: "2 min read",
    image: BlogImage5,
    secondaryImage: BlogImage2,
    author: img3,
    authorName: "Andrea Wise",
    category: "Wellness",
    title: "Bye-Bye Leaks, Hello  Comfortable Periods ",
    datePosted: "August 5, 2024",
    shortDesc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    blogContent: `
   As a mother, it is essential to have open and honest conversations with your daughter about her menstrual cycle. Menstrual bleeding is a natural and normal process that every woman experiences, and it’s crucial to provide your daughter with the right information and support as she navigates this aspect of her life. 

Here are five important things you should tell your daughter about menstrual bleeding:

What is Considered Normal Menstrual Bleeding?
A normal range for menstrual bleeding typically involves a cycle length of 21 to 35 days. During this time, bleeding usually lasts for 2 to 7 days and should not exceed 30 ml of blood. Normal bleeding should not be associated with clots or severe pain that disrupts daily life. If her menstrual cycle falls within these parameters, she can consider it normal.

Recognizing Abnormal Menstrual Bleeding Patterns:
It’s essential for your daughter to know the signs of abnormal menstrual bleeding. If her cycle lasts less than 21 days or more than 35 days, or if she experiences bleeding for less than 2 days or more than 7 days, it might be a cause for concern. Additionally, if she notices clots or experiences severe pain during her periods that affect her daily activities, it is considered abnormal. In such cases, it’s advisable for her to consult a gynecologist to rule out any underlying health issues.

Common Causes of Heavy or Prolonged Menstrual Bleeding:
You should educate your daughter about some common causes of heavy or prolonged menstrual bleeding. These include ovarian causes like simple or complex ovarian cysts, polycystic ovarian syndrome (PCOS), and hormonal disorders. Uterine causes may involve fibroids, adenomyosis, endometrial hyperplasia, and in rare cases, cancer of the endometrium. Cervical causes such as polyps, fibroids, or ectopic growths can also contribute to abnormal bleeding. Additionally, systemic issues like coagulation abnormalities, liver or kidney disorders, hypo or hyperthyroidism, and obesity can impact menstrual bleeding.
The Impact of Hormonal Changes on Menstrual Bleeding:
Hormonal imbalances can significantly affect menstrual bleeding patterns. Lifestyle factors such as a sedentary lifestyle and improper eating habits can contribute to hormonal disturbances. If your daughter experiences irregular periods or notices symptoms like excessive hair growth on her face and chin, hair fall, difficulty losing weight, or sudden weight gain, it’s crucial for her to seek medical evaluation with a gynecologist. Addressing hormonal imbalances early on can prevent potential long-term adverse effects on her health.

Supporting Your Daughter in Tracking Her Menstrual Cycle:
As a mother, you can play an essential role in supporting your daughter in tracking her menstrual cycle. Encourage her to journal her period dates, just like she would with her other emotions. This journaling can be done using a period tracking app or maintaining a diary. By openly discussing her periods and any irregularities she may notice, you will help her understand the significance of regular periods and how to maintain them. If she ever feels uncomfortable or faces any challenges with her menstrual cycle, assure her that you are there to support and guide her.

In conclusion, understanding menstrual bleeding is vital for young girls as they transition into womanhood. By providing them with accurate information and a warm and friendly environment to discuss their menstrual health, we can empower them to make informed decisions about their well-being. Remember, being there for your daughter during this significant phase of her life will strengthen your bond and lay the foundation for open communication in the future.
    `,
  },
  {
    readingTime: "2 min read",
    image: BlogImage1,
    author: img3,
    authorName: "Andrea Wise",

    secondaryImage: BlogImage2,
    category: "Sustainability",
    title: "How to Make Your Home Sanitary ",
    datePosted: "July 12, 2024",
    shortDesc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    blogContent: `
   As a mother, it is essential to have open and honest conversations with your daughter about her menstrual cycle. Menstrual bleeding is a natural and normal process that every woman experiences, and it’s crucial to provide your daughter with the right information and support as she navigates this aspect of her life. 

Here are five important things you should tell your daughter about menstrual bleeding:

What is Considered Normal Menstrual Bleeding?
A normal range for menstrual bleeding typically involves a cycle length of 21 to 35 days. During this time, bleeding usually lasts for 2 to 7 days and should not exceed 30 ml of blood. Normal bleeding should not be associated with clots or severe pain that disrupts daily life. If her menstrual cycle falls within these parameters, she can consider it normal.

Recognizing Abnormal Menstrual Bleeding Patterns:
It’s essential for your daughter to know the signs of abnormal menstrual bleeding. If her cycle lasts less than 21 days or more than 35 days, or if she experiences bleeding for less than 2 days or more than 7 days, it might be a cause for concern. Additionally, if she notices clots or experiences severe pain during her periods that affect her daily activities, it is considered abnormal. In such cases, it’s advisable for her to consult a gynecologist to rule out any underlying health issues.

Common Causes of Heavy or Prolonged Menstrual Bleeding:
You should educate your daughter about some common causes of heavy or prolonged menstrual bleeding. These include ovarian causes like simple or complex ovarian cysts, polycystic ovarian syndrome (PCOS), and hormonal disorders. Uterine causes may involve fibroids, adenomyosis, endometrial hyperplasia, and in rare cases, cancer of the endometrium. Cervical causes such as polyps, fibroids, or ectopic growths can also contribute to abnormal bleeding. Additionally, systemic issues like coagulation abnormalities, liver or kidney disorders, hypo or hyperthyroidism, and obesity can impact menstrual bleeding.
The Impact of Hormonal Changes on Menstrual Bleeding:
Hormonal imbalances can significantly affect menstrual bleeding patterns. Lifestyle factors such as a sedentary lifestyle and improper eating habits can contribute to hormonal disturbances. If your daughter experiences irregular periods or notices symptoms like excessive hair growth on her face and chin, hair fall, difficulty losing weight, or sudden weight gain, it’s crucial for her to seek medical evaluation with a gynecologist. Addressing hormonal imbalances early on can prevent potential long-term adverse effects on her health.

Supporting Your Daughter in Tracking Her Menstrual Cycle:
As a mother, you can play an essential role in supporting your daughter in tracking her menstrual cycle. Encourage her to journal her period dates, just like she would with her other emotions. This journaling can be done using a period tracking app or maintaining a diary. By openly discussing her periods and any irregularities she may notice, you will help her understand the significance of regular periods and how to maintain them. If she ever feels uncomfortable or faces any challenges with her menstrual cycle, assure her that you are there to support and guide her.

In conclusion, understanding menstrual bleeding is vital for young girls as they transition into womanhood. By providing them with accurate information and a warm and friendly environment to discuss their menstrual health, we can empower them to make informed decisions about their well-being. Remember, being there for your daughter during this significant phase of her life will strengthen your bond and lay the foundation for open communication in the future.
    `,
  },
  {
    readingTime: "2 min read",
    image: BlogImage1,
    author: img3,
    authorName: "Andrea Wise",

    secondaryImage: BlogImage2,
    category: "Sustainability",
    title: "How to Make Your Home Sanitary ",
    datePosted: "July 12, 2024",
    shortDesc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
    blogContent: `
   As a mother, it is essential to have open and honest conversations with your daughter about her menstrual cycle. Menstrual bleeding is a natural and normal process that every woman experiences, and it’s crucial to provide your daughter with the right information and support as she navigates this aspect of her life. 

Here are five important things you should tell your daughter about menstrual bleeding:

What is Considered Normal Menstrual Bleeding?
A normal range for menstrual bleeding typically involves a cycle length of 21 to 35 days. During this time, bleeding usually lasts for 2 to 7 days and should not exceed 30 ml of blood. Normal bleeding should not be associated with clots or severe pain that disrupts daily life. If her menstrual cycle falls within these parameters, she can consider it normal.

Recognizing Abnormal Menstrual Bleeding Patterns:
It’s essential for your daughter to know the signs of abnormal menstrual bleeding. If her cycle lasts less than 21 days or more than 35 days, or if she experiences bleeding for less than 2 days or more than 7 days, it might be a cause for concern. Additionally, if she notices clots or experiences severe pain during her periods that affect her daily activities, it is considered abnormal. In such cases, it’s advisable for her to consult a gynecologist to rule out any underlying health issues.

Common Causes of Heavy or Prolonged Menstrual Bleeding:
You should educate your daughter about some common causes of heavy or prolonged menstrual bleeding. These include ovarian causes like simple or complex ovarian cysts, polycystic ovarian syndrome (PCOS), and hormonal disorders. Uterine causes may involve fibroids, adenomyosis, endometrial hyperplasia, and in rare cases, cancer of the endometrium. Cervical causes such as polyps, fibroids, or ectopic growths can also contribute to abnormal bleeding. Additionally, systemic issues like coagulation abnormalities, liver or kidney disorders, hypo or hyperthyroidism, and obesity can impact menstrual bleeding.
The Impact of Hormonal Changes on Menstrual Bleeding:
Hormonal imbalances can significantly affect menstrual bleeding patterns. Lifestyle factors such as a sedentary lifestyle and improper eating habits can contribute to hormonal disturbances. If your daughter experiences irregular periods or notices symptoms like excessive hair growth on her face and chin, hair fall, difficulty losing weight, or sudden weight gain, it’s crucial for her to seek medical evaluation with a gynecologist. Addressing hormonal imbalances early on can prevent potential long-term adverse effects on her health.

Supporting Your Daughter in Tracking Her Menstrual Cycle:
As a mother, you can play an essential role in supporting your daughter in tracking her menstrual cycle. Encourage her to journal her period dates, just like she would with her other emotions. This journaling can be done using a period tracking app or maintaining a diary. By openly discussing her periods and any irregularities she may notice, you will help her understand the significance of regular periods and how to maintain them. If she ever feels uncomfortable or faces any challenges with her menstrual cycle, assure her that you are there to support and guide her.

In conclusion, understanding menstrual bleeding is vital for young girls as they transition into womanhood. By providing them with accurate information and a warm and friendly environment to discuss their menstrual health, we can empower them to make informed decisions about their well-being. Remember, being there for your daughter during this significant phase of her life will strengthen your bond and lay the foundation for open communication in the future.
    `,
  },
];
