<template>
  <div class="flex px-3 items-center py-1 bg-[#F1FCF3] rounded-md">
    <img
      src="@/assets/approve.png"
      alt="Approval Icon"
      class="w-4 h-4 lg:mr-2 mr-2"
    />
    <span class="text-sm font-semibold text-[#595959]">208 Reviews</span>
  </div>
</template>

<script>
export default {
  name: "TotalReview",
};
</script>
