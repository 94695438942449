<template>
  <div class="w-full md:w-2/4 md:p-4 flex-col md:flex justify-center">
    <div class="">
      <img
        src="@/assets/contactHeader.png"
        alt="Contact Us"
        class="md:w-72 md:h-72"
      />
      <h2 class="md:text-3xl text-2xl font-bold font-nunito mt-4 md:leading-10">
        Let's talk with us
      </h2>
      <p
        class="text-blacks mt-2 font-normal md:text-base text-sm md:leading-7 leading-5"
      >
        Questions, comments, or suggestions? Simply <br />
        fill in the form and we’ll be in touch shortly.
      </p>

      <div
        class="mt-6 space-y-4 font-nunito md:text-base text-sm font-normal md:leading-6"
      >
        <div class="flex">
          <img
            src="@/assets/locIcon.png"
            alt="Location"
            class="md:mr-4 mr-2 w-5 h-6"
          />
          <span
            >1055 Arthur Ave Elk Groot, 67, <br />
            New Palmas South Carolina.</span
          >
        </div>
        <div class="flex">
          <img
            src="@/assets/phoneIcon.png"
            alt="Phone"
            class="md:mr-4 mr-2 w-5 h-6"
          />
          <span>+1 234 678 9108 99</span>
        </div>
        <div class="flex">
          <img
            src="@/assets/mail.png"
            alt="Email"
            class="md:mr-4 w-5 h-6 mr-2"
          />
          <span>Woofreesupport@gmail.com</span>
        </div>
      </div>
    </div>
  </div>
</template>
