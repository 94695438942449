<template>
  <div
    class="min-h-screen lg:py-10 lg:px-0 font-roboto md:mt-16 mt-10 mx-4 md:mx-8 lg:mx-24"
  >
    <!-- Privacy Policy Title -->
    <h1
      class="lg:text-left text-left lg:text-6xl text-3xl font-bold md:mb-8 mb-4 text-[#28B14C] capitalize"
    >
      Cookie Policy
    </h1>

    <!-- Banner Image -->
    <div class="lg:w-full lg:h-96 mb-8">
      <img
        src="@/assets/slideOne.png"
        alt="Banner Image"
        class="lg:w-full lg:h-full h-auto object-cover"
      />
    </div>

    <!-- Disclaimer and Privacy Sections Loop -->
    <section
      v-for="(section, index) in policySections"
      :key="index"
      class="bg-white rounded-lg md:p-6 mx-auto"
    >
      <h2
        class="md:text-4xl text-2xl leading-[100%] mt-10 lg:mt-0 md:mb-7 text-left md:leading-relaxed font-bold mb-7 capitalize font-poppins text-[#2B3029]"
      >
        {{ section?.title }}
      </h2>
      <p
        v-for="(paragraph, i) in splitContent(section.content)"
        :key="i"
        class="text-left lg:text-lg text-xs md:leading-6 font-nunito text-[#4E4E4E] font-normal leading-relaxed mb-4"
        v-html="paragraph"
      ></p>
    </section>
  </div>
</template>

<script>
export default {
  name: "PrivacyPage",
  data() {
    return {
      policySections: [
        {
          title: "How We Use Cookies",
          content: [
            ` Improve your browsing experience: Cookies help us understand how you interact with our website and make it easier for you to navigate.

Provide personalized content: We may use cookies to tailor the content and offers you see on our website based on your interests.

Track website performance: Cookies help us monitor website traffic, identify technical issues, and improve the overall user experience.

Process orders and payments: Cookies are essential for processing your orders and payments securely.

Remember your login information: Cookies help us remember your login details so you don't have to re-enter them each time you visit.`,
          ],
        },
        {
          title: "Types of Cookies We Use",
          content: [
            `Essential cookies: These cookies are necessary for the website to function properly and cannot be disabled.\n 
Performance cookies: These cookies collect information about how you use our website, such as which pages you visit and how long you spend on them.\n
Functional cookies: These cookies allow us to remember your preferences, such as your language settings and the products you've viewed.
Targeting cookies: These cookies are used to deliver targeted advertising based on your interests.`,
          ],
        },
        {
          title: "Changes to Our Cookie Policy",
          content: [
            `We may update our Cookie Policy from time to time. Any changes will be posted on this page.`,
          ],
        },
      ],
    };
  },
  methods: {
    splitContent(content) {
      // Split each content string by newlines and join with <br>
      return content.map((paragraph) => paragraph.split("\n").join("<br />"));
    },
  },
};
</script>
