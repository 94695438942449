<template>
  <div
    class="lg:flex overflow-hidden lg:items-start flex-col md:h-auto lg:flex-col md:flex-col items-center lg:p-6 p-5 rounded-[21px] lg:rounded-[48px] md:px-5 lg:shadow-lg"
    :style="{ backgroundColor: testimonial.cardBgColor }"
  >
    <div class="flex flex-row">
      <img
        :src="testimonial.image"
        alt="customer image"
        class="lg:w-16 md:h-16 w-12 h-12 rounded-full object-cover mb-5 items-center md:mr-5 mr-3"
      />
      <div class="flex flex-col justify-between">
        <div class="flex lg:items-start flex-col md:items-start items-start">
          <h3
            class="lg:text-xl text-base font-inter font-medium lg:text-left text-center"
          >
            {{ testimonial.name }}
          </h3>
          <p class="lg:text-sm text-xs text-[#525252] font-lato mt-1">
            {{ testimonial.date }}
          </p>
        </div>
      </div>
    </div>

    <div
      class="mt-0 lg:mt-0 lg:p-4 p-6 rounded-[25px] md:w-auto"
      :style="{ backgroundColor: testimonial.textBgColor }"
    >
      <p
        class="text-white font-normal font-nunito lg:text-base md:text-base text-xs leading-5 lg:leading-6 text-left"
      >
        {{ testimonial.text }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    testimonial: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.transition-transform {
  transition: transform 0.5s ease;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
