<template>
  <div class="md:mt-5">
    <h2 class="md:text-lg font-bold my-5 md:text-left md:my-3 font-nunito">
      <div class="flex gap-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="26"
          height="26"
          viewBox="0 0 26 26"
          fill="none"
        >
          <g clip-path="url(#clip0_1369_13612)">
            <path
              d="M25.6929 12.3779L24.5268 11.4831L25.4216 10.317C25.7358 9.90752 25.5761 9.31145 25.0992 9.11391L23.7413 8.55144L24.3037 7.19347C24.5013 6.71663 24.1927 6.18219 23.681 6.11487L22.2237 5.92303L22.4156 4.46572C22.4829 3.95404 22.0466 3.51764 21.5349 3.58502L20.9122 3.66699V22.3342L21.5349 22.4162C22.0466 22.4836 22.4829 22.0472 22.4156 21.5355L22.2237 20.0782L23.681 19.8864C24.1927 19.819 24.5013 19.2846 24.3037 18.8078L23.7413 17.4498L25.0992 16.8873C25.5761 16.6899 25.7358 16.0938 25.4216 15.6843L24.5268 14.5181L25.6929 13.6234C26.1024 13.3092 26.1024 12.6921 25.6929 12.3779Z"
              fill="#05AEEF"
            />
            <path
              d="M19.8855 23.681L20.0774 22.2237L20.9119 22.3336C21.1578 22.1878 21.3184 21.8787 21.2786 21.5349L21.1099 20.0776L22.3912 19.8858C22.8412 19.8184 23.1125 19.284 22.9388 18.8072L22.4442 17.4492L23.6382 16.8867C24.0575 16.6892 24.1979 16.0932 23.9217 15.6837L23.1349 14.5175L24.1603 13.6228C24.5203 13.3086 24.5203 12.6915 24.1603 12.3773L23.1349 11.4825L23.9217 10.3164C24.1979 9.9069 24.0575 9.31084 23.6382 9.1133L22.4442 8.55082L22.9388 7.19286C23.1125 6.71602 22.8412 6.18158 22.3912 6.11425L21.1099 5.92242L21.2786 4.46511C21.3184 4.12125 21.1578 3.81223 20.9119 3.66638L20.0773 3.77629L20.0608 3.65084L19.8854 2.31904C19.818 1.8073 19.2837 1.49881 18.8068 1.69629L17.4489 2.25877L16.8864 0.900806C16.6888 0.423965 16.0928 0.26425 15.6833 0.578448L14.5172 1.47322L13.6225 0.307096C13.3083 -0.102365 12.6912 -0.102365 12.377 0.307096L11.4822 1.47322L10.3161 0.578448C9.90666 0.26425 9.31059 0.423965 9.11306 0.900806L8.55058 2.25877L7.19261 1.69629C6.71577 1.49881 6.18133 1.80735 6.11401 2.31904L5.92217 3.77629L4.46492 3.58446C3.95318 3.51708 3.51683 3.95343 3.58421 4.46516L3.77605 5.92242L2.31879 6.11425C1.80711 6.18163 1.49851 6.71602 1.69605 7.19286L2.25853 8.55082L0.900562 9.1133C0.423721 9.31084 0.264006 9.90685 0.578204 10.3164L1.47298 11.4825L0.306851 12.3773C-0.102609 12.6915 -0.102609 13.3086 0.306851 13.6228L1.47298 14.5175L0.578204 15.6837C0.264006 16.0931 0.423721 16.6892 0.900562 16.8867L2.25853 17.4492L1.69605 18.8072C1.49851 19.284 1.80706 19.8184 2.31879 19.8858L3.77605 20.0776L3.58421 21.5349C3.51683 22.0466 3.95318 22.4829 4.46492 22.4156L5.92217 22.2237L6.11401 23.681C6.18139 24.1927 6.71577 24.5012 7.19261 24.3037L8.55058 23.7413L9.11306 25.0992C9.31054 25.5761 9.90661 25.7358 10.3161 25.4216L11.4822 24.5268L12.377 25.6929C12.6912 26.1024 13.3083 26.1024 13.6225 25.6929L14.5173 24.5268L15.6834 25.4216C16.0929 25.7358 16.6889 25.5761 16.8865 25.0992L17.449 23.7413L18.8069 24.3037C19.2838 24.5013 19.8181 24.1927 19.8855 23.681Z"
              fill="#05AEEF"
            />
            <path
              d="M16.8841 13.7461C15.1505 13.7461 13.7452 15.1514 13.7452 16.8849C13.7452 18.6185 15.1505 20.0238 16.8841 20.0238C18.6176 20.0238 20.0229 18.6185 20.0229 16.8849C20.0229 15.1514 18.6176 13.7461 16.8841 13.7461ZM16.8841 18.4543C16.0173 18.4543 15.3147 17.7517 15.3147 16.8849C15.3147 16.0182 16.0173 15.3155 16.8841 15.3155C17.7509 15.3155 18.4535 16.0182 18.4535 16.8849C18.4535 17.7517 17.7509 18.4543 16.8841 18.4543Z"
              fill="#FFFBDB"
            />
            <path
              d="M9.11588 5.97754C7.38236 5.97754 5.97705 7.38285 5.97705 9.11637C5.97705 10.8499 7.38236 12.2552 9.11588 12.2552C10.8494 12.2552 12.2547 10.8499 12.2547 9.11637C12.2547 7.38285 10.8494 5.97754 9.11588 5.97754ZM9.11588 10.6858C8.24915 10.6858 7.54647 9.98316 7.54647 9.11637C7.54647 8.24964 8.24915 7.54696 9.11588 7.54696C9.98267 7.54696 10.6853 8.24964 10.6853 9.11637C10.6853 9.98316 9.98262 10.6858 9.11588 10.6858Z"
              fill="#FFFBDB"
            />
            <path
              d="M7.28477 18.7159C6.97832 18.4094 6.97832 17.9126 7.28477 17.6061L17.6054 7.28551C17.9119 6.97905 18.4087 6.97905 18.7151 7.28551C19.0216 7.59196 19.0216 8.08878 18.7151 8.39524L8.39451 18.7159C8.08805 19.0223 7.59123 19.0223 7.28477 18.7159Z"
              fill="#FFFBDB"
            />
          </g>
          <defs>
            <clipPath id="clip0_1369_13612">
              <rect width="26" height="26" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <p>Buy more Save More</p>
      </div>
    </h2>

    <div class="grid grid-cols-3 md:grid-cols-3 gap-2 lg:gap-4">
      <CardPack
        v-for="(pack, index) in packs"
        :key="index"
        :title="pack.title"
        :tickText="pack.tickText"
        :crossText="pack.crossText"
        :isSelected="selectedPack === index"
        @select="selectPack(index)"
        :showDot="false"
        :useRadioButton="true"
        :widthClass="pack.widthClass"
      />
    </div>
  </div>
</template>

<script>
import CardPack from "./CardPack.vue";

export default {
  components: {
    CardPack,
  },
  data() {
    return {
      selectedPack: null,
      packs: [
        {
          title: "4 Boxes",
          tickText: "₹ 150/each",
          crossText: "₹ 300/each",
          widthClass: "w-40",
        },
        {
          title: " 6 Boxes",
          tickText: "₹ 150/each",
          crossText: "₹ 300/each",
          widthClass: "w-40",
        },
        {
          title: "8 Boxes",
          tickText: "₹ 150/each",
          crossText: "₹ 300/each",
          widthClass: "w-40",
        },
      ],
    };
  },
  methods: {
    selectPack(index) {
      this.selectedPack = index;
    },
  },
};
</script>
