// src/router.js
import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/HomePage.vue";
import AllProducts from "@/components/AllProducts/AllProducts.vue";
import LogOne from "@/components/LoginScreen/LogOne.vue";
import LogTwo from "@/components/LoginScreen/LogTwo.vue";
import LogThree from "@/components/LoginScreen/LogThree.vue";
import WooCashPage from "@/components/WooCash/WooCashPage.vue";
// import UserProfilePage from "@/components/UserProfile/UserProfilePage.vue";
import ReferralPage from "@/components/Referral/ReferralPage.vue";
import ContactPage from "@/components/ContactUs/ContactPage.vue";
// import OrderPage from "@/components/YourOrders/OrderPage.vue";
import EditAddress from "@/components/Address/EditAddress.vue";
import ProductDetail from "@/components/ProductDetail/ProductDetail.vue";
import PrivacyPage from "@/components/privacyPage/PrivacyPage.vue";
import TermOfService from "@/components/TermOfService/TermOfService.vue";
import CookiesPolicy from "@/components/CookiesPolicy/CookiesPolicy.vue";
import AboutPage from "@/components/AboutUs/AboutPage.vue";
import ReturnCancelPage from "@/components/ReturnCancelPolicy/ReturnCancelPage.vue";
import BlogDetails from "@/components/OurBlogs/BlogDetails.vue";
import AllBlogs from "@/components/OurBlogs/AllBlog/AllBlogsPage.vue";
import OrderHistoryPage from "@/components/UserProfile/OrderHistoryPage.vue";
import EditProfilePage from "@/components/UserProfile/EditProfilePage.vue";
import AddressPage from "@/components/Address/AddressPage.vue";

const routes = [
  {
    path: "/",
    name: "HomePage",
    component: Home,
  },
  {
    path: "/about-us",
    name: "AboutPage",
    component: AboutPage,
  },
  {
    path: "/logone",
    name: "LogOne",
    component: LogOne,
  },
  {
    path: "/logtwo",
    name: "LogTwo",
    component: LogTwo,
  },
  {
    path: "/logthree",
    name: "LogThree",
    component: LogThree,
  },
  {
    path: "/user-profile",
    name: "UserProfile",
    component: EditProfilePage,
  },
  {
    path: "/your-orders",
    name: "OrderPage",
    component: OrderHistoryPage,
  },
  {
    path: "/address-page",
    name: "AddressPage",
    component: AddressPage,
  },
  {
    path: "/edit-address",
    name: "EditAddress",
    component: EditAddress,
  },
  {
    path: "/contact-us",
    name: "ContactPage",
    component: ContactPage,
  },
  {
    path: "/woo-cash",
    name: "WooCashPage",
    component: WooCashPage,
  },
  {
    path: "/all-blogs",
    name: "AllBlogs",
    component: AllBlogs,
  },
  {
    path: "/blogs/:id",
    name: "blog-detail",
    component: BlogDetails,
  },
  {
    path: "/referral",
    name: "ReferralPage",
    component: ReferralPage,
  },
  {
    path: "/products",
    name: "Products",
    component: AllProducts,
  },
  {
    path: "/product/:id",
    name: "ProductDetail",
    component: ProductDetail,
  },
  {
    path: "/return-cancel-policy",
    name: "ReturnCancelPage",
    component: ReturnCancelPage,
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPage",
    component: PrivacyPage,
  },
  {
    path: "/cookies-policy",
    name: "CookiesPolicy",
    component: CookiesPolicy,
  },
  {
    path: "/term-of-service",
    name: "TermOfService",
    component: TermOfService,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router;
