<template>
  <div class="container mt-8 lg:mt-0">
    <h2
      class="lg:text-3xl font-medium font-poppins md:py-0 text-left mx-4 md:mx-0 lg:mb-4"
    >
      Order History
    </h2>
    <table class="hidden lg:table w-full border-none bg-[#FCFBF7]">
      <thead class="border-b shadow">
        <tr class="lg:text-lg font-medium font-nunito lg:leading-5 p-8">
          <th class="py-4 px-4">Order ID</th>
          <th class="py-4 px-4">Product</th>
          <th class="py-4 px-4">Status</th>
          <th class="py-4 px-4">Delivery</th>
          <th class="py-4 px-4">Shipping To</th>
          <th class="py-4 px-4">Amount</th>
        </tr>
      </thead>
      <tbody class="lg:text-sm font-nunito lg:mt-3 shadow">
        <tr
          v-for="order in orders"
          :key="order.id"
          class="hover:bg-gray-50 border-b"
        >
          <td class="py-6 px-4">{{ order.id }}</td>
          <td class="py-6 px-4">
            <img
              :src="order.productImage"
              alt="Product Image"
              class="w-16 h-16 object-cover border border-[#28B14C] rounded-xl lg:p-3"
            />
          </td>
          <td :class="getStatusClass(order.status)" class="py-6 px-4 font-bold">
            {{ order.status }}
          </td>
          <td class="py-6 px-4 text-black">{{ order.delivery }}</td>
          <td class="py-6 px-4">{{ order.shippingTo }}</td>
          <td class="py-6 px-4 font-bold">{{ order.amount }}</td>
        </tr>
        <tr
          v-for="order in filteredDeliveredOrders"
          :key="'review-' + order.id"
        >
          <td colspan="6" class="py-6">
            <ReviewPrompt />
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Card view for small devices -->
    <div class="lg:hidden space-y-4 md:p-8 p-4">
      <div
        v-for="order in orders"
        :key="order.id"
        class="bg-white p-4 rounded-lg border"
      >
        <div class="flex items-center space-x-4">
          <img
            :src="order.productImage"
            alt="Product Image"
            class="w-16 h-16 object-cover"
          />
          <div class="text-sm space-y-1">
            <p class="font-normal text-[#525252]">Order ID: {{ order.id }}</p>
            <p :class="getStatusClass(order.status)">
              Status: {{ order.status }}
            </p>
            <p class="text-[#28B14C] text-sm font-normal">
              Delivery: {{ order.delivery }}
            </p>
          </div>
        </div>
        <ReviewPrompt v-if="order.status === 'Delivered'" />
      </div>
    </div>
  </div>
</template>

<script>
import { orderHistoryData } from "./order";
import ReviewPrompt from "./ReviewPrompt.vue";

export default {
  components: {
    ReviewPrompt,
  },
  data() {
    return {
      orders: orderHistoryData,
    };
  },
  computed: {
    filteredDeliveredOrders() {
      return this.orders.filter((order) => order.status === "Delivered");
    },
  },
  methods: {
    getStatusClass(delivery) {
      switch (delivery) {
        case "In transit":
          return "text-blue-500";
        case "Delivered":
          return "text-green-500";
        case "Order Confirmed":
          return "text-yellow-500";
        case "Order Delayed":
          return "text-orange-500";
        case "Order Cancelled":
          return "text-red-500";
        default:
          return "text-gray-500";
      }
    },
  },
};
</script>

<style scoped>
.shadow {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.shadowb {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
</style>
