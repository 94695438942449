<template>
  <header class="flex justify-center items-center p-4 bg-[#DEFAE5] mt-10">
    <nav
      class="flex space-x-4 cursor-pointer font-bold md:space-x-12 lg:space-x-24 text-xs md:text-base lg:text-lg leading-6 font-inter"
    >
      <a href="#menstruation" class="text-black hover:text-[#28B14C]">
        Menstruation
      </a>
      <a href="#skin" class="text-black hover:text-[#28B14C]">Skin</a>
      <a href="#wellness" class="text-black hover:text-[#28B14C]">Wellness</a>
      <a href="#expert-says" class="text-black hover:text-[#28B14C]">
        Expert Says
      </a>
      <!-- Updated the link here -->
      <a
        @click.prevent="navigateToProducts"
        class="text-black hover:text-[#28B14C]"
      >
        ShopWoo
      </a>
    </nav>
  </header>
</template>

<script>
export default {
  methods: {
    navigateToProducts() {
      // Using Vue Router to navigate to the Products page
      this.$router.push({ name: "Products" });
    },
  },
};
</script>
