<template>
  <div
    :class="[
      'border rounded-lg p-2 cursor-pointer transition-all h-auto',
      isSelected ? 'border-green-500 bg-green-50' : 'border-gray-300',
    ]"
    widthClass
    @click="onSelect"
  >
    <!-- Pack Title -->
    <h3 class="md:text-sm text-xs font-bold flex items-center mb-2">
      <!-- Conditionally render dot or radio button based on `useRadioButton` prop -->
      <span
        v-if="!useRadioButton && showDot"
        class="md:mr-2 mr-0 w-2 text-xl hidden md:inline"
        :style="{ color: color }"
        >&#8226;</span
      >
      <input
        v-else-if="useRadioButton"
        type="radio"
        :checked="isSelected"
        class="md:mr-2 mr-0"
      />
      {{ title }}
    </h3>

    <!-- Tick and Description -->
    <div class="flex items-start">
      <component :is="tickIcon" v-if="tickIcon" width="15" height="15" />
      <span
        class="md:text-xs text-xs font-normal font-nunito leading-5 md:ml-2"
      >
        {{ tickText }}
      </span>
    </div>

    <div class="flex items-start md:my-2">
      <component :is="crossIcon" v-if="crossIcon" width="15" height="15" />
      <span
        class="md:text-xs text-xs font-normal font-nunito leading-5 md:ml-2"
      >
        {{ crossText }}
      </span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    tickText: String,
    crossText: String,
    isSelected: Boolean,
    color: {
      type: String,
      default: "#28B14C",
    },
    showDot: {
      type: Boolean,
      default: true,
    },
    useRadioButton: {
      type: Boolean,
      default: false,
    },
    widthClass: {
      type: String,
      default: "auto",
    },
  },
  methods: {
    onSelect() {
      this.$emit("select");
    },
  },
};
</script>
