<template>
  <div class="">
    <h1
      class="lg:text-5xl text-3xl font-extrabold font-nunito text-left mb-10 text-[#28B14C]"
    >
      {{ policyContent.title }}
    </h1>

    <p
      v-for="(paragraph, index) in policyContent.description"
      :key="index"
      class="text-left lg:text-lg text-xs md:leading-6 font-nunito text-[#4E4E4E] font-normal leading-relaxed mb-4"
    >
      {{ paragraph }}
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      policyContent: {
        title: "Return Policy",
        description: [
          "Your personal wellbeing is important to us and we strive to maintain high standards of hygiene. Due to the intimate nature of Wofree’s menstrual / personal hygiene & skincare products, once delivered, all products are non-returnable.",
          "However, in the unlikely event of damaged, defective or different / wrong items delivered to you, please write to us at care@Wofree.com with the details and supporting image(s)/video(s). We consider all such cases individually. Upon receipt of the grievance, we will conduct a full and thorough examination and notify you within a reasonable time. Please note that we accept order-related concerns (e.g., damage, defective, different product(s)) only up to 7 days from the date of delivery.",
        ],
      },
    };
  },
};
</script>
