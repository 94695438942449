<template>
  <div class="flex lg:items-start justify-center w-full mt-8 md:mt-0">
    <div class="md:px-8 px-4 rounded-lg w-full">
      <h2
        class="md:text-3xl text-lg font-medium leading-normal md:mb-6 text-left"
      >
        Profile
      </h2>

      <div
        class="my-8 lg:py-10 md:px-10 md:bg-[#FCFBF7] md:shadow bg-white md:mt-0 radius md:border border-none"
      >
        <div class="flex flex-col md:flex-row lg:gap-20 gap-7 md:mb-6">
          <div class="w-full">
            <label
              for="firstName"
              class="block lg:text-sm font-normal text-[#242424] leading-5"
              >First Name <span class="text-red-500">*</span></label
            >
            <input
              type="text"
              id="firstName"
              class="lg:mt-3 mt-1 p-2 block w-full lg:w-60 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <div class="w-full">
            <label
              for="lastName"
              class="block lg:text-sm font-normal text-[#242424] leading-5"
              >Last Name <span class="text-red-500">*</span></label
            >
            <input
              type="text"
              id="lastName"
              class="lg:mt-3 mt-1 p-2 block w-full lg:w-60 border border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        </div>

        <div
          class="flex flex-col md:flex-row lg:gap-20 gap-7 lg:mb-6 mt-7 md:mt-0"
        >
          <div class="w-full">
            <label
              for="phone"
              class="block lg:text-sm font-normal text-[#242424] leading-5"
              >Phone Number <span class="text-red-500">*</span></label
            >
            <input
              type="tel"
              id="phone"
              class="lg:mt-3 mt-1 p-2 block lg:w-60 border w-full border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
          <div class="w-full">
            <label
              for="email"
              class="block lg:text-sm font-normal text-[#242424] leading-5"
              >Email <span class="text-red-500">*</span></label
            >
            <input
              type="email"
              id="email"
              class="lg:mt-3 mt-1 p-2 block lg:w-60 border w-full border-gray-300 rounded-md focus:outline-none focus:ring-blue-500 focus:border-blue-500"
            />
          </div>
        </div>
        <!-- Buttons: Save and Cancel -->
        <div
          class="flex gap-4 lg:mt-10 my-8 justify-start lg:text-sm font-semibold"
        >
          <button
            class="bg-[#28B14C] text-white lg:py-2 py-2 md:text-xl px-4 rounded-md lg:w-72 w-1/2"
          >
            Save
          </button>
          <button
            class="lg:py-2 py-2 px-4 rounded-md border md:text-xl border-[#28B14C] lg:w-72 w-1/2"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "EditProfile",
};
</script>
<style>
.shadow {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.radius {
  border-radius: 15px 15px 0px 0px;
}
</style>
