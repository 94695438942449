import img1 from "@/assets/customerImg.png";
import img2 from "@/assets/customerImg.png";
import img3 from "@/assets/customerImg.png";

export const testimonials = [
  {
    name: "John Doe",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse ",
    date: "May 8, 2020",
    image: img1,
    cardBgColor: "rgba(40, 177, 76, 0.20)",
    textBgColor: "#28B14C",
  },
  {
    name: "Jane Smith",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse ",
    date: "May 8, 2020",
    image: img2,
    cardBgColor: "rgba(5, 174, 239, 0.20)",
    textBgColor: "#05AEEF",
  },
  {
    name: "Alex Johnson",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse ",
    date: "May 8, 2020",
    image: img3,
    cardBgColor: "rgba(40, 177, 76, 0.20)",
    textBgColor: "#28B14C",
  },
  {
    name: "John Doe2",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse ",
    date: "May 8, 2020",
    image: img1,
    cardBgColor: "rgba(5, 174, 239, 0.20)",
    textBgColor: "#05AEEF",
  },
  {
    name: "Jane Smith2",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse ",
    date: "May 8, 2020",
    image: img2,
    cardBgColor: "rgba(40, 177, 76, 0.20)",
    textBgColor: "#28B14C",
  },
  {
    name: "Alex Johnson2",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse sed magna eget nibh in turpis. Consequat duis diam lacus arcu. Faucibus venenatis felis id augue sit cursus pellentesque enim Lorem ipsum dolor sit amet, consectetur adipiscing elit. Cursus nibh mauris, nec turpis orci lectus maecenas. Suspendisse ",
    date: "May 8, 2020",
    image: img3,
    cardBgColor: "rgba(5, 174, 239, 0.20)",
    textBgColor: "#05AEEF",
  },
];
