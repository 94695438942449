<template>
  <div class="flex lg:w-full">
    <div class="rounded-lg">
      <h2
        class="lg:text-3xl text-lg font-medium leading-normal md:mb-5 text-left"
      >
        {{ addressHeading }}
      </h2>

      <!-- Check if hasAddress is true; if not, show "No Address Available" message -->
      <div
        v-if="hasAddress"
        class="border-2 rounded-[20px] mb-6 md:bg-[#FCFBF7] mx-4 md:mx-0 shadow bg-white md:mt-0 radius md:border border-none"
      >
        <!-- Address Card -->
        <div class="flex md:gap-32 gap-0 p-6 md:p-5">
          <div>
            <div class="">
              <h2 class="lg:text-lg font-bold text-sm leading-6 md:mb-4 mb-2">
                {{ address.name }}
              </h2>
              <p
                class="text-[#242424] md:text-sm leading-6 font-normal md:mb-2 mb-3"
              >
                {{ address.street }}
              </p>
              <p
                class="text-[#242424] lg:text-sm text-sm leading-6 mb-3 md:mb-5"
              >
                {{ address.phone }}
              </p>
              <div class="flex md:space-x-6 space-x-4 font-nunito md:my-6">
                <button
                  @click="showEditModal = true"
                  class="md:text-sm font-bold leading-5"
                >
                  Edit
                </button>
                <button class="md:text-sm font-cabin font-bold leading-5">
                  Remove
                </button>
              </div>
            </div>
          </div>
          <div
            class="flex flex-col md:items-end items-start mt-2 md:mt-0 space-y-7"
          >
            <button
              class="bg-[#28B14C] text-white font-cabin font-semibold px-4 text-sm md:py-2 py-1 rounded-md"
            >
              {{ address.label }}
            </button>
          </div>
        </div>
        <div class="flex justify-center lg:mx-8">
          <button
            @click="addAddress"
            class="px-6 w-full lg:mb-8 mb-7 lg:py-2 py-2 mx-6 md:mx-0 rounded border border-[#28B14C] bg-[#28B14C] text-white font-medium text-lg"
          >
            + Add Address
          </button>
        </div>
      </div>

      <!-- Display 'No Address Available' when hasAddress is false -->
      <div v-else>
        <BlankAddress />
      </div>

      <!-- Edit Address Modal -->
      <EditAddress
        v-if="showEditModal"
        :isVisible="showEditModal"
        @close="showEditModal = false"
      />
    </div>
  </div>
</template>
<script>
import BlankAddress from "./BlankAddress.vue";
import EditAddress from "./EditAddress.vue";

export default {
  components: {
    EditAddress,
    BlankAddress,
  },
  data() {
    return {
      showEditModal: false,
      hasAddress: true,
      address: {
        name: "Priyanshi Agarwal",
        street:
          "106 Kiran Chandra Singha Road, Shibpur Howrah-711102, Kolkata, Bengal",
        phone: "7439892375",
        label: "Home",
      },
    };
  },
  computed: {
    addressHeading() {
      return this.hasAddress ? "Saved Address" : "";
    },
  },
  methods: {
    addNewAddress() {
      this.hasAddress = true;
    },
    addAddress() {
      // Logic to trigger the "Add New Address" modal or action
      this.$emit("addAddress");
    },
  },
};
</script>

<style>
.shadow {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.radius {
  border-radius: 15px 15px 0px 0px;
}
</style>
