<template>
  <BaseLayout>
    <SavedAddress />
  </BaseLayout>
</template>

<script>
import { defineComponent } from "vue";

import SavedAddress from "./SavedAddress.vue";
import BaseLayout from "../UserProfile/BaseLayout.vue";

export default defineComponent({
  components: {
    BaseLayout,
    SavedAddress,
  },
});
</script>
