<template>
  <button
    @click="handleClick"
    :class="buttonClasses"
    class="md:py-3 py-2 md:w-[250px] md:px-4 rounded cursor-pointer transition-colors duration-300 mt-6"
  >
    <slot>
      <span class="font-poppins font-medium text-sm md:text-2xl"
        >Add to Cart</span
      >
    </slot>
  </button>
</template>

<script>
export default {
  name: "AddToCartButton",
  props: {
    bgColor: {
      type: String,
      default: "bg-white", // Default background color
    },
    borderColor: {
      type: String,
      default: "border-[#28B14C]", // Default border color
    },
    textColor: {
      type: String,
      default: "text-[#28B14C]", // Default text color
    },
  },
  computed: {
    buttonClasses() {
      return `${this.bgColor} ${this.textColor} border ${this.borderColor}`;
    },
  },
  methods: {
    handleClick() {
      this.$emit("add-to-cart");
    },
  },
};
</script>
