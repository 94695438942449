import { createStore } from "vuex";

export default createStore({
  state: {
    cartItems: [],
    isCartVisible: false,
  },
  mutations: {
    ADD_TO_CART(state, product) {
      const existingItem = state.cartItems.find(
        (item) => item.id === product.id
      );
      if (existingItem) {
        existingItem.quantity += 1;
      } else {
        state.cartItems.push({ ...product, quantity: 1 });
      }
      state.isCartVisible = true;
    },
    INCREASE_QUANTITY(state, item) {
      item.quantity += 1;
    },
    DECREASE_QUANTITY(state, item) {
      if (item.quantity > 1) {
        item.quantity -= 1;
      }
    },
    REMOVE_ITEM(state, item) {
      state.cartItems = state.cartItems.filter(
        (cartItem) => cartItem.id !== item.id
      );
    },
    CLOSE_CART(state) {
      state.isCartVisible = false;
    },
  },
  actions: {
    addToCart({ commit }, product) {
      commit("ADD_TO_CART", product);
    },
    increaseQuantity({ commit }, item) {
      commit("INCREASE_QUANTITY", item);
    },
    decreaseQuantity({ commit }, item) {
      commit("DECREASE_QUANTITY", item);
    },
    removeItem({ commit }, item) {
      commit("REMOVE_ITEM", item);
    },
    closeCart({ commit }) {
      commit("CLOSE_CART");
    },
  },
});
