<template>
  <div class="w-full mt-8">
    <GenericAccordion
      :content="disposalInstructionsContent"
      title="HOW TO DISPOSE"
    />
  </div>
</template>

<script>
import GenericAccordion from "../Acccordian/GenericAccordian.vue";

export default {
  components: {
    GenericAccordion,
  },
  data() {
    return {
      disposalInstructionsContent: `
        <p>Proper disposal of sanitary pads is crucial for maintaining hygiene and protecting the environment. Here are the recommended steps:</p>
        <ol class="list-decimal pl-6">
          <li>Wrap the used pad securely in its self-dispose pouch or in toilet paper.</li>
          <li>Place the wrapped pad in a trash bin. Do not flush it down the toilet.</li>
          <li>Make sure the trash bin has a lid to prevent any odors.</li>
          <li>Regularly dispose of the contents of the trash bin to maintain cleanliness.</li>
          <li>Consider using biodegradable disposal options when available.</li>
        </ol>
        <p>By following these steps, you help ensure that disposal is both hygienic and eco-friendly.</p>
      `,
    };
  },
};
</script>
