<template>
  <div class="flex justify-center items-center">
    <div class="max-w-4xl mx-auto py-8">
      <h2
        class="md:text-xl text-base font-bold font-inter text-left md:mb-4 mb-8 text-[#05AEEF]"
      >
        How It Works:
      </h2>
      <div class="flex items-start">
        <!-- Timeline -->
        <div class="relative">
          <!-- Vertical line with custom color -->
          <div
            class="w-1 h-full absolute left-4"
            style="background-color: var(--color-black-12, rgba(0, 0, 0, 0.12))"
          ></div>

          <div
            v-for="(step, index) in steps"
            :key="index"
            class="flex items-center mb-8"
          >
            <div
              class="z-10 w-10 h-10 flex items-center justify-center text-sm md:text-base font-bold text-[#A4D8BF]"
              style="
                border-radius: 19px;
                background: var(--color-white-solid, #fff);
                box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
                  0px 1px 1px 0px rgba(0, 0, 0, 0.14),
                  0px 1px 3px 0px rgba(0, 0, 0, 0.12);
              "
            >
              {{ index + 1 }}
            </div>

            <div class="ml-8 font-nunito">
              <button class="md:text-base text-xs font-bold">
                {{ step.buttonLabel }}
              </button>
              <p class="text-gray-600 font-normal md:text-base text-xs">
                {{ step.description }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HowItWorks",
  data() {
    return {
      steps: [
        {
          buttonLabel: "Sign in, or sign up:",
          description:
            "Log into, or create, your Nua account to start referring.",
        },
        {
          buttonLabel: "Share your invite code:",
          description:
            "Share the unique invite code with your friends and family to let them join Nua.",
        },
        {
          buttonLabel: "Get ₹100 cashback:",
          description:
            "Once your referred friend makes a purchase, you receive ₹100 cashback instantly.",
        },
        {
          buttonLabel: "Repeat. Reap the Rewards:",
          description:
            "Keep referring more friends, and keep earning cashbacks and rewards.",
        },
      ],
    };
  },
};
</script>
