<template>
  <div
    class="flex flex-col justify-center self-center items-center h-full text-center"
  >
    <div class="flex justify-center items-start">
      <img
        src="@/assets/blankaddress.png"
        alt="No Address Icon"
        class="w-16 h-16 lg:w-80 lg:h-80 mb-4"
      />
    </div>

    <!-- Message Section -->
    <h2 class="text-xl font-medium text-gray-700 lg:leading-5">
      No Address Available
    </h2>

    <div class="lg:my-8 w-full">
      <button
        @click="addAddress"
        class="px-6 py-3 rounded-lg border border-[#28B14C] bg-[#28B14C] text-white font-medium text-lg"
      >
        + Add Address
      </button>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    addAddress() {
      // Logic to trigger the "Add New Address" modal or action
      this.$emit("addAddress");
    },
  },
};
</script>

<style scoped>
/* Custom styles, if needed */
</style>
