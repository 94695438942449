<template>
  <div class="flex items-center justify-between p-4 border-b">
    <img
      :src="item.img"
      alt="Product Image"
      class="md:w-24 md:h-28 md:mr-4 object-cover border-2 rounded-lg border-[#28B14C] md:p-3"
    />
    <div class="ml-4 flex-1">
      <div class="flex md:justify-between">
        <h4 class="md:text-base font-poppins font-normal">
          {{ item.name }}
        </h4>

        <button @click="removeItem">
          <img src="@/assets/Delete.png" />
        </button>
      </div>

      <p class="text-gray-500">{{ item.price }}</p>
      <div class="flex items-center mt-2">
        <button
          @click="decreaseQuantity"
          class="border px-2 border-[#D9D9D9] rounded-lg"
        >
          <span class="text-[#40AA54]">-</span>
        </button>
        <span class="mx-2">{{ item.quantity }}</span>
        <button
          @click="increaseQuantity"
          class="border px-2 border-[#D9D9D9] rounded-lg"
        >
          <span class="text-[#40AA54]">+ </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: Object,
  },
  methods: {
    increaseQuantity() {
      this.$emit("increase-quantity", this.item);
    },
    decreaseQuantity() {
      this.$emit("decrease-quantity", this.item);
    },
    removeItem() {
      this.$emit("remove-item", this.item);
    },
  },
};
</script>
