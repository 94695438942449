<template>
  <div class="lg:mt-14">
    <BlogHeader />
    <MainBlogList />
    <div>
      <ExpertArticles style="background: rgba(217, 217, 217, 0.5)" />
    </div>
  </div>
</template>

<script>
import BlogHeader from "./BlogHeader.vue";
import ExpertArticles from "./ExpertArticles.vue";
import MainBlogList from "./MainBlogList.vue";

export default {
  components: {
    BlogHeader,
    MainBlogList,
    ExpertArticles,
  },
};
</script>
