<template>
  <div
    class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
  >
    <div
      class="bg-white rounded-lg shadow-lg w-full h-full max-w-md max-h-screen md:max-h-[100vh] relative flex flex-col justify-between"
    >
      <button
        @click="$emit('close')"
        class="absolute top-4 right-4 text-red-500 text-4xl"
      >
        &times;
      </button>

      <div class="flex flex-col items-center">
        <img
          src="@/assets/signUpImg.png"
          alt="Login Image"
          class="w-full h-auto mb-6"
        />
        <div class="p-6">
          <h1 class="text-2xl font-bold text-[#1E293B] font-lato mb-4">
            Enter your Phone Number
          </h1>

          <input
            type="tel"
            placeholder="+91 743982375"
            class="w-full p-3 border rounded-lg mb-4 border-black focus:outline-none"
          />

          <p class="text-[#28B14C] mb-4 font-normal text-base">
            Notify me of the order on this number
          </p>

          <button
            @click="$emit('openLoginModalTwo')"
            class="w-full p-3 bg-[#28B14C] text-white rounded-md font-poppins font-medium text-lg mb-4"
          >
            Log in with OTP
          </button>

          <p class="text-center text-sm text-gray-500 font-poppins w-full mb-4">
            By proceeding, I accept to the
            <a href="#" class="underline">Terms & Conditions</a> and
            <a href="#" class="underline">Privacy Policy</a>.
          </p>
        </div>
      </div>
    </div>
  </div>
  <LogTwo v-if="showLoginModal" @close="showLoginModal = false" />
</template>

<script>
import LogTwo from "./LogTwo.vue";

export default {
  name: "LogOne",
  components: {
    LogTwo,
  },
  data() {
    return {
      openLoginModalTwo() {
        this.showLoginModal = true;
      },
    };
  },
};
</script>
