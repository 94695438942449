<template>
  <div class="mt-8">
    <GenericAccordion :content="accordionContent" title="PRODUCT INFORMATION" />
  </div>
</template>

<script>
import GenericAccordion from "../Acccordian/GenericAccordian.vue";

export default {
  components: {
    GenericAccordion,
  },
  data() {
    return {
      accordionContent: `
        <p>Be Me sanitary pads aim to make the most uncomfortable days of your life the most comfortable. The pads are ultra-thin and lined with a soft top sheet to maximize comfort. They are made of oxy biodegradable material and 100% certified organic cotton. Our pads also have a 5x fluid retention capacity, which means they can be worn for long days and longer nights. The pads come with a self-dispose pouch that ensures environmental-friendly disposal.</p>
        <ul class="list-disc pl-6">
          <li>Chlorine-free</li>
          <li>Artificial fragrance-free</li>
          <li>Rash-free</li>
          <li>Skin-friendly</li>
          <li>Capable of handling all types of flows</li>
          <li>Certified as per I.S Standards</li>
        </ul>
      `,
    };
  },
};
</script>
