<template>
  <div
    class="modal-overlay fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
    @click="$emit('close')"
  >
    <div
      class="modal-content relative rounded-lg overflow-hidden w-full h-full max-w-xl sm:max-w-xl sm:h-[80vh] md:h-[95vh]"
    >
      <button
        @click="$emit('close')"
        class="absolute top-4 right-4 text-red-500 cursor-pointer"
      >
        ✕
      </button>

      <!-- Video Section -->
      <div class="video-container">
        <video
          :src="product.video"
          class="w-full h-full object-cover"
          autoplay
          muted
          loop
        ></video>
      </div>

      <!-- Icons on the bottom right, positioned above the product details -->
      <div class="absolute bottom-[11rem] right-4 flex flex-col space-y-2 z-10">
        <img
          src="@/assets/share.png"
          alt="Share"
          class="w-8 h-8 cursor-pointer"
        />
        <img
          src="@/assets/ShoppingCart.png"
          alt="Add to Cart"
          class="w-8 h-8 cursor-pointer"
        />
      </div>

      <!-- Product Details Section -->
      <div
        class="product-details absolute bottom-0 left-0 flex flex-col w-full"
      >
        <div class="flex flex-row blured p-4">
          <div class="bg-white rounded-xl p-2 mr-4">
            <img
              :src="product.img"
              alt="Product Image"
              class="w-16 h-16 rounded-md"
            />
          </div>

          <div class="">
            <h3
              class="text-sm leading-relaxed font-medium text-white font-poppins"
            >
              {{ product.name }}
            </h3>
            <p
              class="text-sm leading-relaxed font-medium text-white font-poppins"
            >
              {{ product.price }}
            </p>
          </div>
        </div>

        <button class="mb-4 bg-[#28B14C] text-left text-white px-4 py-2">
          Shop Now
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    product: Object,
  },
};
</script>

<style scoped>
.modal-overlay {
  z-index: 50;
}

.blured {
  background: rgba(45, 45, 45, 0.4);
  backdrop-filter: blur(10px);
}

.modal-content {
  max-height: 100vh;
}

.video-container {
  width: 100%;
  height: 100%; /* Ensures video takes up full height */
}
</style>
