<template>
  <div>
    <MainSlider />
    <MainFeature />
    <ProductList />
    <BestsellerProduct />
    <SeenIn />
    <MarqueeText />
    <ChooseUs />
    <BestsellerProduct />
    <OurCustomer />
    <MainBanner />
    <ProductFeature />
    <OurBlog />
  </div>
</template>

<script>
import MainSlider from "@/components/Slider/MainSlider.vue";
import MainFeature from "@/components/MainFeature/MainFeature.vue";
import ProductList from "@/components/ProductList/ProductList.vue";
import BestsellerProduct from "@/components/BestsellerProduct/BestsellerProduct.vue";
import SeenIn from "@/components/SeenIn/SeenIn.vue";
import OurCustomer from "@/components/OurCustomer/OurCustomer.vue";
import MarqueeText from "@/components/MarqueeText/MarqueeText.vue";
import ChooseUs from "@/components/ChooseUs/ChooseUs.vue";
import MainBanner from "@/components/MainBanner/MainBanner.vue";
import ProductFeature from "@/components/ProductFeature/ProductFeature.vue";
import OurBlog from "@/components/OurBlogs/OurBlog.vue";

export default {
  name: "HomePage",
  components: {
    MainSlider,
    MainFeature,
    ProductList,
    BestsellerProduct,
    SeenIn,
    MarqueeText,
    OurCustomer,
    ChooseUs,
    MainBanner,
    ProductFeature,
    OurBlog,
  },
  methods: {
    goToAllProducts() {
      this.$router.push("/products");
    },
  },
};
</script>
