<template>
  <div class="flex flex-col items-start mb-6 space-y-4">
    <!-- Profile Image -->
    <img
      :src="user.profileImage"
      alt="Profile Image"
      class="lg:w-24 lg:h-24 rounded-full object-cover mb-4"
    />

    <!-- Name -->
    <div
      class="flex flex-col items-start lg:text-base font-normal lg:leading-5 lg:space-y-1 font-nunito"
    >
      <p class="font-nunito font-normal">{{ user.name }}</p>
      <p class="font-nunito font-normal">{{ user.phone }}</p>
      <p class="font-nunito font-normal">{{ user.email }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "UserInfo",
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
};
</script>
