<template>
  <div class="flex space-x-2 mb-4">
    <input
      v-for="(digit, index) in otp"
      :key="index"
      type="tel"
      maxlength="1"
      v-model="otp[index]"
      class="w-11 h-11 p-3 border rounded-[10px] border-[#28B14C] focus:outline-none text-center text-2xl"
      @input="handleInput(index)"
      ref="otpInputs"
    />
  </div>
</template>

<script>
export default {
  name: "OtpInput",
  data() {
    return {
      otp: ["", "", "", "", "", ""], // Array to hold OTP values
    };
  },
  methods: {
    handleInput(index) {
      // Move focus to the next input box when a digit is entered
      if (this.otp[index]) {
        const nextInput = index + 1;
        if (nextInput < this.otp.length) {
          this.$refs.otpInputs[nextInput].focus();
        }
      } else if (index > 0 && !this.otp[index]) {
        // Move focus to the previous input box if the current box is empty and it's not the first input
        const prevInput = index - 1;
        this.$refs.otpInputs[prevInput].focus();
      }
    },
    // Method to get the OTP as a single string
    getOtp() {
      return this.otp.join("");
    },
  },
};
</script>

<style scoped>
/* Optional: Style adjustments for the inputs */
input[type="tel"] {
  font-size: 1.5rem; /* Larger font for better visibility */
  text-align: center; /* Center align the text */
}
</style>
