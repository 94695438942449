<template>
  <div
    class="flex lg:items-start lg:flex-col flex-col md:my-10 my-10 mt-10 mx-6 lg:mx-24 md:mt-20"
  >
    <span
      class="md:text-2xl text-xl md:text-left text-center font-bold font-nunito mb-5 md:mb-10"
    >
      As seen in-
    </span>

    <!-- Swiper for small screens -->
    <swiper
      v-if="isMobile"
      :breakpoints="{
        0: {
          slidesPerView: 4,
          spaceBetween: 10,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 10,
        },
      }"
      :autoplay="{ delay: 3000, disableOnInteraction: false }"
      class="mySwiper flex items-center"
    >
      <swiper-slide v-for="(src, index) in images" :key="index">
        <img :src="src" :alt="'Icon ' + (index + 1)" class="w-30 h-6 mr-2" />
      </swiper-slide>
    </swiper>

    <!-- Static grid for larger screens -->
    <div
      v-else
      class="grid grid-cols-3 md:grid-cols-5 lg:gap-[104px] items-center"
    >
      <img
        v-for="(src, index) in images"
        :key="index"
        :src="src"
        :alt="'Icon ' + (index + 1)"
        class="w-36"
      />
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/swiper-bundle.css";
import SwiperCore, { Autoplay } from "swiper";

SwiperCore.use([Autoplay]);

export default {
  name: "SeenIn",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      images: [
        require("@/assets/s1.png"),
        require("@/assets/s2.png"),
        require("@/assets/s3.png"),
        require("@/assets/s4.png"),
        require("@/assets/s5.png"),
      ],
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth < 768;
    },
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.$forceUpdate();
    },
  },
};
</script>

<style scoped>
.mySwiper {
  width: 100%;
}
</style>
