<template>
  <div
    class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
  >
    <div
      class="bg-white md:h-screen md:p-6 rounded-lg shadow-lg w-full max-w-md relative flex flex-col justify-between"
    >
      <!-- Close Icon -->
      <button
        @click="$emit('close')"
        class="absolute top-4 right-4 text-red-500 text-4xl"
      >
        &times;
      </button>

      <div class="flex flex-col items-center md:px:4">
        <img
          src="@/assets/logThree.png"
          alt="Login Image"
          class="w-full mb-6"
        />

        <!-- OTP Input Component -->
        <OtpInput ref="otpInput" class="mb-4" />

        <!-- Notification Text -->
        <p class="text-[#28B14C] mb-4 font-normal text-base text-center">
          Didn’t receive any code?
          <span @click="resendCode" class="cursor-pointer underline"
            >Resend New Code</span
          >
        </p>

        <!-- Continue Button -->
        <button
          class="w-full md:p-3 bg-[#28B14C] text-white rounded-md font-poppins font-medium text-lg mb-6"
        >
          Continue
        </button>

        <!-- Terms & Conditions Text -->
        <p class="text-center md:text-sm text-gray-500 font-poppins w-full">
          By proceeding, I accept to the
          <a href="#" class="underline"> Terms & Conditions</a> and
          <a href="#" class="underline"> Privacy Policy</a>.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import OtpInput from "./OtpInput.vue";

export default {
  name: "LoginOne",
  components: {
    OtpInput,
  },
  methods: {
    continueToLogThree() {
      // Get the OTP value for further processing
      const otpValue = this.$refs.otpInput.getOtp();
      console.log("Entered OTP:", otpValue); // Debugging line
      // Redirect to LogThree.vue (ensure you have a valid route)
      this.$router.push({ name: "LogThree" });
    },
    resendCode() {
      // Logic to resend the OTP code (to be implemented)
      console.log("Resending OTP code...");
    },
  },
};
</script>
