<template>
  <!-- Set a fixed height here -->
  <section
    class="about-us h-max md:mt-0 px-4 md:px-0 mt-3 lg:mb-8 mb-4 md:mb-0"
  >
    <div
      class="flex flex-col lg:flex-row items-stretch justify-between lg:h-[460px] md:h-auto"
    >
      <div class="right-col w-full lg:w-1/2 flex items-center justify-center">
        <img
          :src="aboutImage"
          alt="About Us Image"
          class="lg:w-max lg:h-max w-80 h-56 object-cover"
        />
      </div>
      <div
        class="left-col w-full lg:w-1/2 md:pl-12 md:py-16 lg:py-0 md:pr-16 flex flex-col justify-center overflow-hidden md:h-full lg:h-[460px] md:px-0"
      >
        <p
          class="md:text-base font-poppins font-bold leading-releaxed md:mb-4 text-[#4E4E4E] mb-3"
        >
          Made by you. Made for you
        </p>
        <p
          class="leading-relaxed text-[#4E4E4E] font-poppins md:text-base text-sm font-normal capitalize"
        >
          Pat yourself on your back because without your help, we wouldn't have
          grown in the first place. With innovation in our mind and you at the
          heart of it, every single creation of ours is thought around you to
          help you have a better wellness journey.
        </p>
      </div>
    </div>
  </section>
</template>

<script>
import aboutImage from "@/assets/aboutTwo.png";

export default {
  name: "AboutUsTwo",
  data() {
    return {
      aboutImage,
    };
  },
};
</script>
<style scoped>
.custom-shadow {
  background: #fff;
  box-shadow: 0px -4px 4px 0px rgba(40, 177, 76, 0.35),
    50px 50px 100px 0px rgba(40, 177, 76, 0.35) inset;
}
</style>
