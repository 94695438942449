<template>
  <div class="flex items-center px-3 py-1 bg-[#F1FCF3] rounded-md">
    <!-- Stars -->
    <div class="flex space-x-1 mr-2">
      <span
        v-for="(star, index) in stars"
        :key="index"
        :class="star ? 'text-yellow-400' : 'text-gray-300 lg:w-4 lg:h-4'"
        >★</span
      >
    </div>
    <!-- Rating Text -->
    <span class="md:text-sm font-semibold text-[#595959]">4.8</span>
  </div>
</template>

<script>
export default {
  name: "StarRating",
  data() {
    return {
      stars: [true, true, true, true, false],
    };
  },
};
</script>
