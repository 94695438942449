<template>
  <div class="flex flex-col md:items-start items-center justify-start">
    <!-- <div class="flex space-x-2"> -->
    <h1
      class="md:text-3xl text-base leading-6 mt-6 text-center md:mt-0 font-bold font-nunito"
    >
      Refer and Earn! 🎁
    </h1>
    <!-- </div> -->

    <p
      class="md:text-lg text-sm text-center md:text-left leading-5 font-nunito font-normal mt-4"
    >
      Share Woo with your friends, and get exclusive rewards when they join the
      community.
      <!-- <br class="hidden md:inline" /> -->
    </p>
  </div>

  <div class="flex justify-center">
    <img
      src="@/assets/referHeader.png"
      alt="Referral Image"
      class="mt-1 md:w-auto md:h-auto w-52 h-52"
    />
  </div>
</template>

<script>
export default {
  name: "ReferralHeader",
};
</script>
