<template>
  <div class="flex items-center justify-center bg-[#28B14C] md:mt-10 lg:mt-0">
    <p
      class="md:text-xl text-xs font-medium md:py-7 py-4 text-center font-nunito text-white md:leading-relaxed capitalize"
    >
      Now that you know us well enough, we'll see you at your doorstep.
    </p>
  </div>
</template>

<script>
export default {
  name: "AboutFour",
};
</script>
