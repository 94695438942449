<template>
  <div class="max-w-3xl bg-green-700 rounded shadow-lg py-4 px-4">
    <div class="flex justify-between gap-4 self-center items-center">
      <!-- Left Column -->
      <div>
        <h2 class="text-white md:text-lg text-sm font-bold font-nunito mb-2">
          Refer Now
        </h2>
        <p
          class="text-white md:text-sm text-xs leading-4 font-nunito font-normal"
        >
          {{
            isLoggedIn
              ? "Share your unique code with your friends and family to unlock best offers when your friend logs in"
              : "Log in, and share your invite code. That's all you have to do."
          }}
        </p>
      </div>
      <div>
        <button
          v-if="isLoggedIn"
          @click="copyCode"
          class="bg-white font-nunito md:text-base text-xs text-green-700 font-bold md:py-2 py-1 md:px-4 px-4 rounded hover:bg-gray-100"
        >
          <div class="flex">
            <img src="@/assets/copyIcon.png" class="h-3 w-3" alt="Copy Icon" />
            <span class="lg:text-base text-xs">{{ inviteCode }}</span>
          </div>
        </button>
        <button
          v-else
          class="bg-white font-nunito w-max md:text-base text-xs text-green-700 font-bold md:py-2 py-2 px-4 md:px-4 rounded-lg shadow-md"
        >
          Get Code
        </button>
      </div>
    </div>
  </div>

  <!-- New Referral Code Input Section (only visible when logged in) -->
  <div
    v-if="isLoggedIn"
    class="md:mt-6 my-5 md:my-0 flex md:p-5 p-4 justify-between shadow-sm items-center gap-4 bg-[#F7F8F9] rounded"
  >
    <div class="text-[#339933]">
      <p class="md:text-lg font-bold text-xs font-nunito">Refer Now</p>
      <p class="lg:text-base font-normal text-xs font-nunito">
        Enter unique code to earn cashback in your wallet
      </p>
    </div>
    <div>
      <input
        type="text"
        class="bg-white placeholder-gray-500 border border-dashed border-[#000] text-green-700 text-xs font-nunito rounded-lg py-2 px-4 focus:outline-none focus:ring-2 focus:ring-green-500 w-full"
        placeholder="Enter your referral code"
      />
    </div>
  </div>
  <div class="flex justify-center">
    <button
      @click="openRedeemPopup"
      class="bg-[#28B14C] shadow-sm text-sm lg:mt-9 w-1/3 rounded text-white font-nunito font-semibold leading-4 py-3"
    >
      Redeem Now
    </button>
  </div>
  <!-- 
  <div class="mt-7 md:hidden">
    <p class="mb-6 text-[#05AEEF] font-normal leading-5 font-nunito text-sm">
      Share Via:
    </p>
    <button
      class="bg-[#28B14C] rounded-md w-full text-white py-3 text-sm font-medium leading-normal"
    >
      WhatsApp
    </button>
  </div> -->

  <RedeemPopup v-if="showRedeemPopup" @close="showRedeemPopup = false" />
</template>

<script>
import RedeemPopup from "./RedeemPop.vue";

export default {
  name: "ReferNow",
  components: { RedeemPopup },
  data() {
    return {
      isLoggedIn: true,
      inviteCode: "lorem123",
      showRedeemPopup: false,
    };
  },
  methods: {
    copyCode() {
      navigator.clipboard
        .writeText(this.inviteCode)
        .then(() => {
          alert("Invite code copied to clipboard!");
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    },
    openRedeemPopup() {
      this.showRedeemPopup = true;
    },
  },
};
</script>
