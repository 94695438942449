<!-- <template>
  <div class="">
    <ReferralHeader />
    <ReferNow />
    <HowItWorks />
  </div>
</template>

<script>
import HowItWorks from "./HowItWorks.vue";
import ReferNow from "./ReferNow.vue";
import ReferralHeader from "./ReferralHeader.vue";

export default {
  name: "ReferralPage",
  components: {
    ReferralHeader,
    HowItWorks,
    ReferNow,
  },
};
</script> -->

<template>
  <BaseLayout>
    <div class="flex flex-col w-full lg:px-0 px-4 my-6">
      <ReferralHeader />

      <ReferNow />
      <HowItWorks />
    </div>
  </BaseLayout>
</template>

<script>
import { defineComponent } from "vue";
import ReferralHeader from "./ReferralHeader.vue";
import ReferNow from "./ReferNow.vue";
import BaseLayout from "../UserProfile/BaseLayout.vue";
import HowItWorks from "./HowItWorks.vue";

export default defineComponent({
  components: {
    BaseLayout,
    ReferralHeader,
    HowItWorks,
    ReferNow,
  },
});
</script>
