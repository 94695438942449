<template>
  <div class="bg-white rounded-lg">
    <h1
      class="lg:text-base text-sm font-bold md:mb-3 mb-3 font-inter leading-relaxed text-[#28B14C] md:mt-8"
    >
      Check Estimate Delivery Date
    </h1>

    <div class="flex items-center md:mb-4">
      <input
        type="text"
        v-model="pincode"
        placeholder="Enter your Pincode"
        class="border border-[#28B14C] rounded-lg p-2 w-full md:w-full"
      />
      <!-- <button
        @click="changePincode"
        class="text-[#28B14C] rounded-r-lg p-2 text-base"
      >
        Change
      </button> -->
    </div>

    <p v-if="pincode" class="text-[#05AEEF] text-sm">
      Delivery by {{ deliveryDate }} - {{ pincode }}
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      pincode: "",
      deliveryDate: this.calculateDeliveryDate(),
    };
  },
  methods: {
    calculateDeliveryDate() {
      const date = new Date();
      date.setDate(date.getDate() + 5);
      return date.toLocaleDateString("en-US", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      });
    },
    changePincode() {
      if (this.pincode) {
        this.deliveryDate = this.calculateDeliveryDate();
      }
    },
  },
};
</script>
