<template>
  <div class="px-4 py-8 md:mx-10 md:mt-14">
    <div class="grid grid-cols-1 lg:grid-cols-2 gap-10">
      <div class="text-left md:text-center space-y-6">
        <h1
          class="text-2xl leading-normal font-poppins text-[#28B14C] font-medium md:mb-12"
        >
          Know about our Sanitary Pad
        </h1>

        <div class="grid grid-cols-1 sm:grid-cols-3 gap-6 md:mt-12">
          <div
            v-for="(item, index) in columnData"
            :key="index"
            class="space-y-2"
          >
            <template v-if="item.type === 'text'">
              <p
                class="text-base font-poppins md:text-left font-bold text-[#595959]"
              >
                {{ item.title }}
              </p>
              <p
                class="text-xs font-poppins lg:text-left font-normal leading-normal text-[#595959]"
              >
                {{ item.description }}
              </p>
            </template>

            <template v-if="item.type === 'image'">
              <div class="flex justify-center items-center">
                <img
                  :src="item.src"
                  :alt="item.alt"
                  class="w-[373px] h-auto object-cover"
                />
              </div>
            </template>
          </div>
        </div>
      </div>

      <div class="md:space-y-6 space-y-2">
        <h2
          class="lg:text-lg md:text-left text-base font-semibold leading-normal font-poppins text-left text-[#28B14C]"
        >
          {{ rightColumnData.subheading }}
        </h2>

        <!-- Paragraph Text -->
        <p
          class="md:text-sm text-xs text-left text-[#595959] font-poppins font-normal"
        >
          {{ rightColumnData.description }}
        </p>

        <!-- List -->
        <ul
          class="list-disc list-inside md:text-sm text-xs text-left space-y-1"
        >
          <li v-for="(item, index) in rightColumnData.features" :key="index">
            <span
              class="md:text-sm text-left text-[#595959] font-poppins font-normal"
            >
              {{ item }}</span
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "KnowAboutOurPad",
  data() {
    return {
      columnData: [
        {
          type: "text",
          title: "100% Cotton",
          description:
            "It is made of oxy biodegradable material and 100% certified organic cotton.",
        },
        {
          type: "image",
          src: require("@/assets/know-about.png"),
          alt: "Sanitary Pad Image",
        },
        {
          type: "text",
          title: "Ultra-Thin Top",
          description:
            "The pads are ultra-thin and lined with a soft top sheet to maximize comfort.",
        },
      ],

      rightColumnData: {
        subheading: "A Pad that has it all",
        description: `Our sanitary pads aim to make the most uncomfortable days of your life the most comfortable. The pads are ultra-thin and lined with a soft top sheet to maximize comfort. They are made of oxy biodegradable material and 100% certified organic cotton. Our pads also have a 5x fluid retention capacity, which means they can be worn for long days and longer nights. The pads come with a self-dispose pouch that ensures environmental-friendliness.`,
        features: [
          "Chlorine-free",
          "Artificial fragrance-free",
          "Rash-free",
          "Skin-friendly",
          "Capable of handling all types of flows",
          "Certified as per I.S Standards",
        ],
      },
    };
  },
};
</script>
