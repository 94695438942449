<template>
  <div
    class="relative bg-cover bg-center h-auto md:mt-10 mt-14 md:my-10 lg:mx-24 md:mx-8 mx-4 grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 items-center"
  >
    <!-- Image section (different image for mobile) -->
    <div class="flex items-center justify-normal md:mt-10">
      <img
        :src="isMobile ? mobileSquareImage : squareImage"
        alt="Square Image"
        class="md:object-cover object-inherit w-full h-56 md:h-auto"
      />
    </div>
  </div>
</template>

<script>
import squareImage from "@/assets/sqImg.png";
import mobileSquareImage from "@/assets/BannerMob.png";

export default {
  name: "MainBanner",
  data() {
    return {
      squareImage, // Default image path
      mobileSquareImage, // Mobile image path
      isMobile: window.innerWidth < 768, // Initialize mobile detection
    };
  },
  created() {
    // Listen to window resize events to update `isMobile`
    window.addEventListener("resize", this.handleResize);
  },
  unmounted() {
    // Clean up the event listener when component is destroyed
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.isMobile = window.innerWidth < 768;
    },
  },
};
</script>
