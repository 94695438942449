<template>
  <div class="my-10 lg:mx-24 md:mx-8 mx-4">
    <h1
      class="md:text-base font-semibold text-center md:mb-2 mb-2 text-[#53686A] text-base"
    >
      FAQs
    </h1>

    <p
      class="text-center text-2xl text-black mb-2 lg:mb-4 md:text-4xl font-bold"
    >
      Ask us anything
    </p>
    <p
      class="md:text-base text-sm text-center font-normal text-[#53686A] md:text-center lg:mb-10 md:mb-5 mb-5"
    >
      Have any questions? We're here to assist you.
    </p>

    <div
      v-for="(faq, index) in faqs"
      :key="index"
      class="border-b border-gray-200 py-4"
    >
      <div
        class="flex justify-between items-center cursor-pointer"
        @click="toggleAnswer(index)"
      >
        <h2
          class="md:text-xl text-sm font-bold md:font-medium font-nunito text-[#28B14C]"
        >
          {{ faq.question }}
        </h2>
        <img
          :src="icon"
          alt="Dropdown"
          class="transition-transform duration-300"
          :class="{ 'rotate-180': activeIndex === index }"
        />
      </div>

      <div
        v-if="activeIndex === index"
        class="md:mt-2 text-black lg:w-3/4 font-nunito md:font-light font-normal md:text-base text-xs leading-5"
      >
        {{ faq.answer }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeIndex: null,
      faqs: [
        {
          question:
            "Are there eco-friendly or biodegradable sanitary pads available?",
          answer:
            "Yes, eco-friendly pads made from organic cotton or biodegradable materials are available, providing a more sustainable option for menstruation products.",
        },
        {
          question: "Can sanitary pads cause infections?",
          answer:
            "Sanitary pads are generally safe, but leaving a pad on for too long can increase the risk of bacterial growth, leading to potential infections. Regularly changing pads helps prevent this.",
        },
        {
          question: " How do I dispose of used sanitary pads?",
          answer:
            "Wrap the used pad in its wrapper or toilet paper and dispose of it in a trash bin. Avoid flushing pads down the toilet as they can clog plumbing systems.",
        },
        {
          question: "Can sanitary pads cause infections?",
          answer:
            "Sanitary pads are generally safe, but leaving a pad on for too long can increase the risk of bacterial growth, leading to potential infections. Regularly changing pads helps prevent this.",
        },
        {
          question: "Do sanitary pads expire?",
          answer:
            "Sanitary pads generally don’t have an expiration date, but it’s a good idea to store them in a cool, dry place to maintain their quality.",
        },
      ],
      icon: require("@/assets/dropIcon.png"),
    };
  },
  methods: {
    toggleAnswer(index) {
      this.activeIndex = this.activeIndex === index ? null : index;
    },
  },
};
</script>
