<template>
  <div
    class="flex flex-col items-center self-center justify-center h-full p-4 text-gray-500"
  >
    <!-- Display the imported image for the empty cart icon -->
    <img :src="emptyCartImage" alt="Empty Cart" class="lg:w-56 lg:h-56 mb-4" />
    <p class="text-lg font-semibold">Your shopping cart is empty</p>
  </div>
</template>

<script>
import emptyCartImage from "@/assets/emptyCart.png";

export default {
  name: "EmptyCart",
  data() {
    return {
      emptyCartImage,
    };
  },
};
</script>

<style scoped>
/* Additional styling if needed */
</style>
