<template>
  <div class="bg-[#DEFAE5] lg:mx-24">
    <div>
      <div
        class="flex lg:flex-row flex-col items-center lg:space-x-4 space-y-4 lg:space-y-0 lg:py-9 py-4 lg:px-5"
      >
        <span
          class="text-2xl font-bold font-nunito lg:mr-16 text-[#401214] leading-9"
        >
          Sustainability <br class="hidden lg:inline" />
          certifications
        </span>

        <div class="flex lg:space-x-12 md:space-x-16 space-x-3">
          <div
            v-for="(certification, index) in certifications"
            :key="index"
            class="flex flex-col items-center"
          >
            <div
              class="flex items-center justify-center lg:h-20 h-auto w-full mt-6 lg:mt-0"
            >
              <img
                :src="certification.image"
                :alt="'Icon ' + (index + 1)"
                class="md:w-full md:h-full w-7 h-7 object-contain"
              />
            </div>
            <span
              class="lg:text-sm text-[8px] font-bold font-nunito md:leading-5 mt-2 text-center"
            >
              {{ certification.text }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import sc1 from "@/assets/sc1.png";
import sc2 from "@/assets/sc2.png";
import sc3 from "@/assets/sc3.png";
import sc4 from "@/assets/sc4.png";
import sc5 from "@/assets/sc5.png";

export default {
  name: "SustainabilityCertification",
  data() {
    return {
      certifications: [
        { image: sc1, text: "GMP certified" },
        { image: sc2, text: "BIS 5405:2019" },
        { image: sc3, text: "ISO 13485-certified" },
        { image: sc4, text: "FDA Approved" },
        { image: sc5, text: "ISO 14001-2015-certified" },
      ],
    };
  },
};
</script>
