<template>
  <div class="w-full lg:px-8 lg:mb-10 rounded">
    <div class="bg-gray-400 bg-opacity-50 py-2 md:px-9 my-5 md:my-0">
      <p
        class="text-center text-black font-nunito md:text-lg text-xs font-normal leading-[1.4]"
      >
        The maximum cashback allocated to a user cannot exceed 20.000
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "MaxCashBackValue",
};
</script>
