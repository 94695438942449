<template>
  <div class="flex md:flex-col md:bg-[#fff] my-5 lg:my-0">
    <!-- <h2
      class="md:text-3xl text-lg font-medium leading-normal md:mb-6 text-left"
    >
      Woo Cash
    </h2> -->
    <div
      class="rounded-lg lg:p-8 md:bg-[#FCFBF7] radius bg-white md:mt-0 radius border-none text-center md:border border-[#28B14C]"
    >
      <div class="md:border border-[#28B14C] rounded lg:p-8">
        <div
          class="flex md:flex-col flex-row items-center space-x-10 md:space-x-0 bgCustom md:bg-white py-2"
        >
          <div class="flex items-center">
            <img
              src="@/assets/wallet.png"
              alt="Wallet Icon"
              class="w-8 h-8 md:hidden mr-3"
            />

            <h2 class="md:text-lg text-sm font-normal md:mb-5 mb-0 font-nunito">
              Total Available Woo Cash
            </h2>
          </div>

          <div
            class="flex items-center justify-between lg:justify-center space-x-8 md:mb-5"
          >
            <img
              src="@/assets/wallet.png"
              alt="Wallet Icon"
              class="w-8 h-8 hidden md:inline"
            />
            <hr class="md:border-l border-gray-300 h-8 md:mx-5 mx-2" />

            <p class="md:text-lg text-sm font-semibold text-green-500">
              {{ balance }}
            </p>
          </div>
        </div>

        <p
          class="md:text-lg text-xs font-normal text-green-500 font-nunito mt-5 md:mt-0 lg:mb-5"
        >
          Cashback will be credited in the Woopay wallet account within
          <br class="hidden md:inline" />7 days of all product being delivered
          for eligible orders
        </p>
        <!-- Dynamic percentage -->
        <p
          class="font-nunito font-normal md:text-lg text-[#6B6B6B] hidden md:inline"
        >
          {{ cashPercentage }}% of the Grand Total can be paid via Woocash
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TotalWooCash",
  data() {
    return {
      balance: "₹0.00",
      cashPercentage: 50,
    };
  },
};
</script>

<style>
.bgCustom {
  background: rgba(110, 221, 140, 0.6);
}
.shadow {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
.radius {
  border-radius: 15px 15px 0px 0px;
}
</style>
