export const orderHistoryData = [
  {
    id: "ORD123456",
    productImage: require("@/assets/productImg.png"), // replace with your image paths
    status: "In transit",
    delivery: "Delivery Expected by Oct 15, 2024",
    shippingTo: "37A, Alipore Rd, Alipore, Kolkata, West Bengal 700027",
    amount: "₹50.00",
  },

  {
    id: "ORD123457",
    productImage: require("@/assets/productImg.png"), // replace with your image paths
    status: "Order Confirmed",
    delivery: "In Process",
    shippingTo: "10B, Chittaranjan Avenue, Kolkata, West Bengal 700073",
    amount: "₹200",
  },
  {
    id: "ORD123457",
    productImage: require("@/assets/productImg.png"), // replace with your image paths
    status: "Order Delayed",
    delivery: "Will be delivered Soon",
    shippingTo: "10B, Chittaranjan Avenue, Kolkata, West Bengal 700073",
    amount: "₹200",
  },
  {
    id: "ORD123457",
    productImage: require("@/assets/productImg.png"), // replace with your image paths
    status: "Order Cancelled",
    delivery: "Cancelled",
    shippingTo: "10B, Chittaranjan Avenue, Kolkata, West Bengal 700073",
    amount: "₹200",
  },
  {
    id: "ORD123457",
    productImage: require("@/assets/productImg.png"), // replace with your image paths
    status: "Delivered",
    delivery: "Delivered on 24 Aug 2024",
    shippingTo: "10B, Chittaranjan Avenue, Kolkata, West Bengal 700073",
    amount: "₹200",
  },
];
