<template>
  <div class="container md:my-10 px-4">
    <div class="flex items-center justify-center">
      <img
        :src="leftarrowIcon"
        alt="Left Arrow"
        class="arrow md:w-8 md:h-8 w-6 h-6 cursor-pointer hover:opacity-75"
        @click="loadPreviousProducts"
      />

      <div
        class="grid product-grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 flex-1 lg:mx-8"
      >
        <div
          v-for="product in visibleProducts"
          :key="product.id"
          class="product-card relative"
          :class="{ 'transition-transform duration-500 ease-in-out': sliding }"
        >
          <!-- Video Section -->
          <div
            class="video-section w-full h-56 md:h-64 lg:h-96 bg-gray-200 flex items-center justify-center overflow-hidden relative"
          >
            <!-- Displaying the video for each product -->
            <video
              :src="product.video"
              class="w-full h-full object-cover"
              autoplay
              muted
              loop
            ></video>
          </div>
          <div class="mt-4 text-left px-2 absolute bottom-0 w-full blurred-bg">
            <div class="flex items-center gap-2">
              <button
                class="text-white font-medium text-left text-xs md:text-sm p-1 md:p-1"
                @click="openModal(product)"
              >
                View Product
              </button>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="8"
                viewBox="0 0 9 8"
                fill="none"
              >
                <path
                  d="M8.50003 0.5C8.50003 0.223858 8.27617 0 8.00003 0L3.50003 -1.78814e-07C3.22389 -1.78814e-07 3.00003 0.223857 3.00003 0.5C3.00003 0.776142 3.22389 1 3.50003 1L7.50003 1L7.50003 5C7.50003 5.27614 7.72389 5.5 8.00003 5.5C8.27617 5.5 8.50003 5.27614 8.50003 5L8.50003 0.5ZM1.35358 7.85355L8.35358 0.853553L7.64648 0.146447L0.646477 7.14645L1.35358 7.85355Z"
                  fill="white"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>

      <img
        :src="arrowIcon"
        alt="Right Arrow"
        class="arrow md:w-8 md:h-8 w-6 h-6 cursor-pointer hover:opacity-75"
        @click="loadMoreProducts"
      />
    </div>

    <ProductModal
      v-if="isModalOpen"
      :product="selectedProduct"
      @close="closeModal"
    />
  </div>
</template>

<script>
import ProductModal from "./ProductModal.vue";
import { products } from "../../productData";
export default {
  components: {
    ProductModal,
  },
  data() {
    return {
      products: products, // Use the imported products array
      visibleProducts: [],
      productsPerPage: 4,
      currentPage: 0,
      sliding: false,
      arrowIcon: require("@/assets/rightArrow.png"),
      leftarrowIcon: require("@/assets/leftArrow.png"),
      isModalOpen: false,
      selectedProduct: null,
    };
  },
  mounted() {
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.productsPerPage = window.innerWidth < 1024 ? 2 : 4;
      this.loadInitialProducts();
    },
    loadInitialProducts() {
      this.visibleProducts = this.products.slice(
        this.currentPage * this.productsPerPage,
        (this.currentPage + 1) * this.productsPerPage
      );
    },
    loadMoreProducts() {
      this.sliding = true;
      setTimeout(() => {
        this.currentPage =
          (this.currentPage + 1) %
          Math.ceil(this.products.length / this.productsPerPage);
        this.loadInitialProducts();
        this.sliding = false;
      }, 300);
    },
    loadPreviousProducts() {
      this.sliding = true;
      setTimeout(() => {
        this.currentPage =
          (this.currentPage -
            1 +
            Math.ceil(this.products.length / this.productsPerPage)) %
          Math.ceil(this.products.length / this.productsPerPage);
        this.loadInitialProducts();
        this.sliding = false;
      }, 300);
    },
    openModal(product) {
      this.selectedProduct = product;
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
  },
};
</script>

<style scoped>
.blurred-bg {
  background: rgba(45, 45, 45, 0.4);
  backdrop-filter: blur(10px);
}
</style>
