<template>
  <div class="grid grid-cols-1 md:grid-cols-1 lg:gap-6 lg:mt-10">
    <ProfileCard
      :icon="icons.card1"
      title="Your Order"
      description="Return, Order, or buy things again"
      @click="
        () => {
          setActiveCard('order');
          redirectToOrder();
        }
      "
      :isActive="activeCard === 'order'"
    />
    <ProfileCard
      :icon="icons.card2"
      title="Address"
      description="Edit Address for your orders"
      @click="
        () => {
          setActiveCard('address');
          redirectToAddress();
        }
      "
      :isActive="activeCard === 'address'"
    />
    <ProfileCard
      :icon="icons.card3"
      title="Woo Cash"
      description="Use cash from Woo cash wallet"
      @click="
        () => {
          setActiveCard('wooCash');
          redirectToWooCash();
        }
      "
      :isActive="activeCard === 'wooCash'"
    />
    <ProfileCard
      :icon="icons.card4"
      title="Referral"
      description="Refer and Earn Money"
      @click="
        () => {
          setActiveCard('referral');
          redirectToReferral();
        }
      "
      :isActive="activeCard === 'referral'"
    />
    <ProfileCard
      :icon="icons.card5"
      title="Payment Modes"
      description="Your saved UPIs, cards and more"
      @click="
        () => {
          setActiveCard('paymentModes');
        }
      "
      :isActive="activeCard === 'paymentModes'"
    />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import card1 from "@/assets/card1.png";
import card2 from "@/assets/card2.png";
import card3 from "@/assets/card3.png";
import card4 from "@/assets/card4.png";
import card5 from "@/assets/card5.png";
import ProfileCard from "./ProfileCard.vue";

export default defineComponent({
  components: { ProfileCard },
  data() {
    return {
      activeCard: "", // Track which card is active
      icons: {
        card1,
        card2,
        card3,
        card4,
        card5,
      },
    };
  },
  methods: {
    setActiveCard(card) {
      console.log("Setting active card to:", card); // Debugging log
      this.activeCard = card;
    },
    redirectToWooCash() {
      console.log("Redirecting to Woo Cash Page"); // Debugging log
      this.$router.push({ name: "WooCashPage" });
    },
    redirectToReferral() {
      console.log("Redirecting to Referral Page"); // Debugging log
      this.$router.push({ name: "ReferralPage" });
    },
    redirectToOrder() {
      console.log("Redirecting to Order Page"); // Debugging log
      this.$router.push({ name: "OrderPage" });
    },
    redirectToAddress() {
      console.log("Redirecting to Address Page"); // Debugging log
      this.$router.push({ name: "AddressPage" });
    },
  },
});
</script>
