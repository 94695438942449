<template>
  <div
    class="flex flex-col-reverse lg:flex-row lg:justify-between items-center lg:p-8 p-4 lg:mt-14 md:mt-10 md:mx-8 lg:mx-24"
  >
    <ContactDetail />

    <ContactForm />
  </div>
</template>

<script>
import ContactDetail from "./ContactDetail.vue";
import ContactForm from "./ContactForm.vue";

export default {
  components: {
    ContactDetail,
    ContactForm,
  },
};
</script>
