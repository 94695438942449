<template>
  <div
    class="relative flex md:h-16 h-14 md:my-10 my-10 w-full flex-col items-center justify-center overflow-hidden border bg-background bg-[#05AEEF]"
  >
    <div class="marquee">
      <div class="flex" :style="{ animationDuration: `${duration}s` }">
        <span
          v-for="(review, index) in reviews"
          :key="index"
          class="marquee-item text-white font-normal text-base font-poppins"
        >
          {{ review.body }}
          <span class="separator">•</span>
        </span>
        <span
          v-for="(review, index) in reviews"
          :key="'copy-' + index"
          class="marquee-item text-white font-normal lg:text-base text-xs font-poppins"
        >
          {{ review.body }}
          <span class="separator">•</span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      duration: 20, // Adjust this for the speed of the scroll
      reviews: [
        {
          body: "Upgraded quick dry technology",
        },
        { body: "I don't know what to say. I'm speechless. This is amazing." },
        { body: "I'm at a loss for words. This is amazing. I love it." },
        { body: "This is a fantastic experience!" },
        { body: "Truly incredible! I can't recommend it enough." },
        { body: "This has changed my perspective. Amazing work!" },
      ],
    };
  },
  methods: {
    pause() {
      const marquee = document.querySelector(".marquee .flex");
      if (marquee) {
        marquee.style.animationPlayState = "paused";
      }
    },
    play() {
      const marquee = document.querySelector(".marquee .flex");
      if (marquee) {
        marquee.style.animationPlayState = "running";
      }
    },
  },
};
</script>

<style scoped>
.marquee {
  overflow: hidden;
  position: relative;
  width: 100%;
}

.marquee .flex {
  display: flex;
  animation: marquee linear infinite;
  will-change: transform;
  width: calc(
    100% * 2
  ); /* Ensure the marquee width is double the container's width */
}

.marquee-item {
  margin-right: 1.5rem; /* Increased space between items */
  padding: 0.5rem 0; /* Add vertical padding for better spacing */
  white-space: nowrap; /* Prevent text from wrapping to the next line */
}

.separator {
  margin: 0 0.5rem; /* Space around the separator */
}

@keyframes marquee {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%); /* Move to the left by half the width */
  }
}
</style>
