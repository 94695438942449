<template>
  <div
    v-if="show"
    class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center shadow-sm"
  >
    <div
      class="bg-[#FCFBF7] p-8 lg:px-24 rounded-lg w-11/12 md:w-1/2 lg:w-2/3 max-h-screen overflow-y-auto space-y-4 relative"
    >
      <button
        @click="close"
        class="absolute top-4 right-4 lg:mb-2 text-lg font-medium text-grey-500 focus:outline-none"
      >
        X
      </button>

      <div class="shadow p-3 mt-4 flex gap-2">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 30 30"
          fill="none"
        >
          <path
            d="M26.2507 15.0005C26.2507 15.2492 26.152 15.4876 25.9762 15.6634C25.8003 15.8393 25.5619 15.938 25.3132 15.938H6.95113L13.789 22.7747C13.8761 22.8618 13.9452 22.9653 13.9924 23.0791C14.0395 23.1929 14.0638 23.3148 14.0638 23.438C14.0638 23.5612 14.0395 23.6832 13.9924 23.797C13.9452 23.9108 13.8761 24.0142 13.789 24.1013C13.7019 24.1884 13.5985 24.2575 13.4847 24.3046C13.3709 24.3518 13.2489 24.376 13.1257 24.376C13.0026 24.376 12.8806 24.3518 12.7668 24.3046C12.653 24.2575 12.5496 24.1884 12.4625 24.1013L4.02496 15.6638C3.93779 15.5767 3.86864 15.4733 3.82146 15.3595C3.77428 15.2457 3.75 15.1237 3.75 15.0005C3.75 14.8773 3.77428 14.7553 3.82146 14.6415C3.86864 14.5277 3.93779 14.4243 4.02496 14.3372L12.4625 5.89974C12.6384 5.72383 12.877 5.625 13.1257 5.625C13.3745 5.625 13.6131 5.72383 13.789 5.89974C13.9649 6.07565 14.0638 6.31424 14.0638 6.56302C14.0638 6.8118 13.9649 7.05039 13.789 7.2263L6.95113 14.063H25.3132C25.5619 14.063 25.8003 14.1618 25.9762 14.3376C26.152 14.5134 26.2507 14.7519 26.2507 15.0005Z"
            fill="black"
          />
        </svg>
        <h2 class="lg:text-lg text-sm font-bold">
          Tell us about your Experience
        </h2>
      </div>

      <div class="space-y-2 flex justify-between items-center">
        <p class="text-black font-bold md:text-lg text-sm">
          My Experience was very bad
        </p>
        <div class="flex gap-2">
          <span
            v-for="n in 5"
            :key="n"
            class="text-yellow-500 cursor-pointer"
            @click="setRating(n)"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
            >
              <path
                d="M18.3039 9.29013L14.7882 12.3237L15.8593 16.8604C15.9184 17.1067 15.9032 17.365 15.8156 17.6026C15.728 17.8402 15.5719 18.0466 15.3671 18.1956C15.1623 18.3445 14.9179 18.4295 14.6648 18.4396C14.4118 18.4498 14.1614 18.3847 13.9453 18.2526L9.99995 15.8245L6.0523 18.2526C5.83622 18.3839 5.58613 18.4484 5.3335 18.4379C5.08087 18.4273 4.837 18.3423 4.63261 18.1934C4.42822 18.0446 4.27243 17.8386 4.18488 17.6014C4.09732 17.3642 4.08191 17.1064 4.14058 16.8604L5.21558 12.3237L1.69995 9.29013C1.50878 9.1249 1.37052 8.90701 1.30244 8.66368C1.23436 8.42034 1.23947 8.16234 1.31715 7.9219C1.39483 7.68145 1.54162 7.46922 1.73919 7.31171C1.93677 7.15419 2.17637 7.05837 2.42808 7.03622L7.03745 6.66434L8.81558 2.36122C8.91182 2.1267 9.07563 1.9261 9.28618 1.78492C9.49673 1.64374 9.7445 1.56836 9.998 1.56836C10.2515 1.56836 10.4993 1.64374 10.7098 1.78492C10.9204 1.9261 11.0842 2.1267 11.1804 2.36122L12.9578 6.66434L17.5671 7.03622C17.8193 7.05755 18.0596 7.15283 18.2579 7.31013C18.4562 7.46743 18.6037 7.67975 18.6819 7.92048C18.76 8.16122 18.7654 8.41967 18.6973 8.66344C18.6292 8.90722 18.4907 9.12549 18.2992 9.29091L18.3039 9.29013Z"
                :fill="rating >= n ? '#FF9F00' : '#fff'"
                stroke="#FF9F00"
                stroke-width="1"
              />
            </svg>
          </span>
        </div>
      </div>
      <div class="w-full border-b-2 border-dashed border-gry-700 mt-2"></div>

      <!-- Warning Text -->
      <p
        v-if="warningText"
        class="text-red-500 md:text-base text-sm font-medium text-center"
      >
        {{ warningText }}
      </p>

      <div>
        <p class="font-normal text-sm lg:text-center text-[#242424] leading-5">
          What went wrong (Select up to 3 options)
        </p>
        <div class="flex justify-center">
          <div class="flex flex-col gap-4 mt-5">
            <label
              v-for="(option, index) in options"
              :key="index"
              class="flex items-center gap-4"
            >
              <input
                type="checkbox"
                :value="option"
                v-model="selectedOptions"
                class="mr-2 h-5 w-5"
              />
              <span
                class="lg:text-base text-sm font-normal leading-5 text-[#242424]"
                >{{ option }}</span
              >
            </label>
          </div>
        </div>
      </div>
      <div class="w-full border-b-2 border-dashed border-[#6F6F6F] mt-2"></div>
      <div class="flex justify-center">
        <textarea
          v-model="reviewText"
          placeholder="Tell us more..."
          class="w-full p-2 border-2 rounded-lg resize-none text-[#6F6F6F]"
        ></textarea>
      </div>

      <!-- Closing Statement -->
      <p
        class="md:text-base text-sm font-medium leading-1 text-[#242424] text-center"
      >
        Your words are important to help us make your experience better.
      </p>

      <!-- Submit and Cancel Buttons -->
      <div class="flex justify-center item-center md:w-full space-x-4 mt-4">
        <button
          @click="submitReview"
          class="px-4 py-2 bg-[#28B14C] text-white rounded md:text-2xl text-sm lg:w-2/3"
        >
          Submit
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ReviewModal",
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      reviewText: "",
      rating: 1,
      options: [
        "Offer/Discount can be better",
        "Refund not Received",
        "Cashback not received",
        "Products out of stock",
        "Customer support",
        "Order Tracking not easy",
      ],
      selectedOptions: [],
      warningText: "", // added property to store warning text
    };
  },
  watch: {
    // Watch the selected options and update the warning text if needed
    selectedOptions(newValue) {
      if (newValue.length > 3) {
        this.warningText = " Dear User, You have already checked 3 Boxes";
      } else {
        this.warningText = "";
      }
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    setRating(star) {
      this.rating = star;
    },
    submitReview() {
      alert(
        `Review submitted: ${this.reviewText}, Rating: ${
          this.rating
        }, Issues: ${this.selectedOptions.join(", ")}`
      );
      this.reviewText = "";
      this.selectedOptions = [];
      this.rating = 1;
      this.close();
    },
  },
};
</script>

<style scoped>
.fixed {
  z-index: 50;
}
.shadow {
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
</style>
