<template>
  <div class="">
    <h1
      class="lg:text-5xl text-3xl font-extrabold font-nunito text-left mb-10 text-[#28B14C]"
    >
      {{ cancelContent.title }}
    </h1>

    <p
      v-for="(paragraph, index) in cancelContent.description"
      :key="index"
      class="text-left lg:text-lg text-xs md:leading-6 font-nunito text-[#4E4E4E] font-normal leading-relaxed mb-4"
    >
      {{ paragraph }}
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cancelContent: {
        title: "Cancellation Policy",
        description: [
          "Once an order is placed (inclusive of orders that are automatically placed under Auto-repeat Plans), it can be cancelled as long as the order has not been handed over to our delivery partner.",
          "While orders take about a day to be dispatched, we recommend that order cancellation requests be shared within 90 minutes of order confirmation. In case you would like to cancel your order within this timeframe, please contact us via our live chat option or write to us at care@Wofree.com or give us a call at 080-470-93155.",
          "Upon cancellation of prepaid orders, a refund of the order amount will be initiated in 24-48 hours and credited to the source account in another 7-10 working days.",
        ],
      },
    };
  },
};
</script>
