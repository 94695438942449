<template>
  <div
    @click="handleClick"
    :class="[
      'flex md:p-4 md:w-64 cursor-pointer rounded shadow-[0px_1px_4px_rgba(0,0,0,0.14)]',
      isActive ? 'bg-[#84D196]' : 'bg-[#E9FFF4]',
    ]"
  >
    <img :src="icon" alt="Profile Icon" class="w-[40px] h-[40px] mr-4" />
    <div class="flex flex-col">
      <h2 class="md:text-lg font-bold leading-6 font-roboto">{{ title }}</h2>
      <p class="text-black font-nunito font-normal leading-5 text-sm">
        {{ description }}
      </p>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: "",
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleClick() {
      console.log("Card clicked for:", this.title); // Debugging log
      this.$emit("click");
    },
  },
});
</script>
