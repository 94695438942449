<template>
  <div
    class="min-h-screen lg:py-10 lg:px-0 font-roboto md:mt-16 mt-10 mx-4 md:mx-8 lg:mx-24"
  >
    <!-- Privacy Policy Title -->
    <h1
      class="lg:text-left text-left lg:text-6xl text-3xl font-bold md:mb-8 mb-4 text-[#28B14C] capitalize"
    >
      Terms of Service
    </h1>

    <!-- Banner Image -->
    <div class="lg:w-full lg:h-96 mb-8">
      <img
        src="@/assets/slideOne.png"
        alt="Banner Image"
        class="lg:w-full lg:h-full h-auto object-cover"
      />
    </div>

    <!-- Disclaimer and Privacy Sections Loop -->
    <section
      v-for="(section, index) in policySections"
      :key="index"
      class="bg-white rounded-lg lg:p-6 mx-auto"
    >
      <h2
        class="md:text-4xl text-2xl leading-[100%] mt-10 lg:mt-0 md:mb-7 text-left md:leading-relaxed font-bold mb-7 capitalize font-poppins text-[#2B3029]"
      >
        {{ section?.title }}
      </h2>
      <p
        v-for="(paragraph, i) in splitContent(section.content)"
        :key="i"
        class="text-[#4E4E4E] md:text-base text-sm font-poppins capitalize leading-5 font-normal"
        v-html="paragraph"
      ></p>
    </section>
  </div>
</template>

<script>
export default {
  name: "PrivacyPage",
  data() {
    return {
      policySections: [
        {
          title: "",
          content: [
            `This Site offers for sale sanitary pads, Cramp Comfort heat patches, Foaming Intimate Wash, Everyday Liners, Balance essential oil roll-on for low moods, Uplift Period Nutrition Drink Mix, Pore Cleansing Face Wash, Oil-Free Gel Moisturizer, Acne Healing Patch, Detoxifying Clay Mask, Dark Spot Corrector Serum, Period Saviour Kit, Pamper Me Kit, Complete Acne Control Solution, Active Acne Essentials, Acne Control Essentials, Acne Best Sellers Combo, Skin Detox Combo, Clarifying Acne Patch Kit and pouches (hereinafter referred to as “Products”). By accessing the Site or purchasing the Products you agree to terms and conditions set forth herein in this Terms of Use.

We reserve the right to refuse service to anyone for any reason at any time.

Prices of our products/services/subscriptions are subject to change without notice. We reserve the right to modify or discontinue any product/service/subscription (or any part or content thereof) without notice at any time. We shall not be liable to you or to any third-party for any modification, price change, suspension or discontinuance of any product/service/subscription.

We also reserve the right, with or without notice, to reduce the quantity of any product/service/subscription to be provided to you, at our sole discretion.`,
          ],
        },
        {
          title: "Warnings for Woofree Sanitary Pads",
          content: [
            `1. Change your pad every 6-8 hours, even on low flow and spotting days.\n
2. If you require XXL/XXXL pads to manage extremely heavy flow or post-pregnancy flow, Woofree sanitary pads may not be best suited for your flow.\n
3. If you experience any discomfort or irritation while using a pad, kindly discontinue usage. If irritation persists, consult a doctor.`,
          ],
        },
      ],
    };
  },
  methods: {
    splitContent(content) {
      // Split each content string by newlines and join with <br>
      return content.map((paragraph) => paragraph.split("\n").join("<br />"));
    },
  },
};
</script>
