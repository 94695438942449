<template>
  <header
    class="flex items-center justify-center py-4 px-6 lg:p-4 h-auto lg:h-14 bg-custom-blue"
  >
    <div class="flex items-center">
      <img
        src="@/assets/heart.png"
        alt="Promotional Image"
        class="h-auto mr-4"
      />
      <p
        class="lg:text-base text-sm text-center font-normal font-poppins text-[#fff] lg:leading-relaxed leading-4"
      >
        Get 50% off with your first purchase with Woobserver
      </p>
    </div>
  </header>
</template>

<script>
export default {
  name: "NavHeader",
};
</script>
