<template>
  <div>
    <!-- Overlay for mobile dropdown; shown only when showMenu is true -->
    <div
      v-if="showMenu"
      class="fixed inset-0 bg-black bg-opacity-50 z-40"
      @click="toggleMenu"
    ></div>

    <nav
      :class="[
        'bg-[#fff]',
        navbarFixed ? 'fixed' : 'relative',
        'top-0 w-full z-50 py-4 px-6 lg:py-2 lg:px-24 flex md:px-8 justify-between items-center md:border-b border-[#EOEOEO]',
      ]"
    >
      <div class="flex items-center space-x-4 md:space-x-4 lg:space-x-0">
        <div class="lg:hidden flex items-center" @click="toggleMenu">
          <a class="text-4xl cursor-pointer text-[#28B14C]">
            <img src="../../assets/List.png" class="h-6 w-6" />
          </a>
        </div>
        <span class="flex items-center">
          <router-link to="/">
            <img
              src="@/assets/Wofree.png"
              alt="Logo"
              class="w-[80%] sm:w-[80%] md:w-[90%] lg:w-[100%] xl:w-[100%] h-auto max-w-[200px]"
            />
          </router-link>
        </span>
      </div>

      <div class="lg:flex md:flex-1 justify-evenly items-center md:hidden">
        <ul
          class="cursor-pointer space-x-4 md:space-x-8 lg:gap-14 text-black font-normal text-base hidden md:flex font-poppins"
        >
          <li><a @click="navigateTo('Products')">Products</a></li>
          <li><a @click="navigateTo('AllBlogs')">Blog</a></li>
        </ul>
        <SearchBox class="hidden md:flex" />
      </div>

      <div class="flex items-center space-x-6">
        <div class="flex items-center">
          <!-- <img
            src="@/assets/cart.png"
            alt="Cart"
            class="md:flex w-6 h-6 cursor-pointer"
            @click="openCart"
          /> -->
          <div @click="openCart">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M8.125 16.875C8.125 17.1222 8.05169 17.3639 7.91434 17.5695C7.77699 17.775 7.58176 17.9352 7.35335 18.0299C7.12495 18.1245 6.87361 18.1492 6.63114 18.101C6.38866 18.0527 6.16593 17.9337 5.99112 17.7589C5.8163 17.5841 5.69725 17.3613 5.64902 17.1189C5.60079 16.8764 5.62554 16.6251 5.72015 16.3966C5.81476 16.1682 5.97498 15.973 6.18054 15.8357C6.3861 15.6983 6.62777 15.625 6.875 15.625C7.20652 15.625 7.52446 15.7567 7.75888 15.9911C7.9933 16.2255 8.125 16.5435 8.125 16.875ZM15 15.625C14.7528 15.625 14.5111 15.6983 14.3055 15.8357C14.1 15.973 13.9398 16.1682 13.8451 16.3966C13.7505 16.6251 13.7258 16.8764 13.774 17.1189C13.8222 17.3613 13.9413 17.5841 14.1161 17.7589C14.2909 17.9337 14.5137 18.0527 14.7561 18.101C14.9986 18.1492 15.2499 18.1245 15.4784 18.0299C15.7068 17.9352 15.902 17.775 16.0393 17.5695C16.1767 17.3639 16.25 17.1222 16.25 16.875C16.25 16.5435 16.1183 16.2255 15.8839 15.9911C15.6495 15.7567 15.3315 15.625 15 15.625ZM18.7273 5.79219L16.7242 13.0016C16.6141 13.3954 16.3785 13.7425 16.0533 13.9904C15.728 14.2383 15.3308 14.3733 14.9219 14.375H7.2C6.78987 14.3748 6.39105 14.2405 6.06437 13.9925C5.7377 13.7446 5.50108 13.3965 5.39062 13.0016L2.65 3.125H1.25C1.08424 3.125 0.925268 3.05915 0.808058 2.94194C0.690848 2.82473 0.625 2.66576 0.625 2.5C0.625 2.33424 0.690848 2.17527 0.808058 2.05806C0.925268 1.94085 1.08424 1.875 1.25 1.875H3.125C3.26164 1.87497 3.39453 1.91973 3.50331 2.00241C3.6121 2.08509 3.69079 2.20115 3.72734 2.33281L4.46797 5H18.125C18.2214 4.99998 18.3164 5.02224 18.4027 5.06504C18.4891 5.10784 18.5643 5.17001 18.6226 5.24671C18.681 5.32341 18.7208 5.41255 18.7389 5.50718C18.7571 5.6018 18.7531 5.69935 18.7273 5.79219ZM17.3023 6.25H4.81563L6.59766 12.6672C6.63421 12.7989 6.7129 12.9149 6.82169 12.9976C6.93047 13.0803 7.06336 13.125 7.2 13.125H14.9219C15.0585 13.125 15.1914 13.0803 15.3002 12.9976C15.409 12.9149 15.4877 12.7989 15.5242 12.6672L17.3023 6.25Z"
                fill="#28B14C"
              />
            </svg>
          </div>
          <span
            class="hidden md:hidden lg:inline font-nunito bg-clip-text px-2 text-black cursor-pointer"
            @click="$emit('toggle-cart')"
          >
            Cart
          </span>
        </div>

        <div
          class="relative flex items-center"
          @mouseover="showDropLogdown = true"
          @mouseleave="showDropLogdown = false"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M18.0407 16.5624C16.8508 14.5054 15.0172 13.0304 12.8774 12.3312C13.9358 11.7011 14.7582 10.7409 15.2182 9.59821C15.6781 8.45548 15.7503 7.19337 15.4235 6.00568C15.0968 4.81798 14.3892 3.77039 13.4094 3.02378C12.4296 2.27716 11.2318 1.8728 10 1.8728C8.76821 1.8728 7.57044 2.27716 6.59067 3.02378C5.6109 3.77039 4.90331 4.81798 4.57654 6.00568C4.24978 7.19337 4.32193 8.45548 4.78189 9.59821C5.24186 10.7409 6.06422 11.7011 7.12268 12.3312C4.98284 13.0296 3.14925 14.5046 1.9594 16.5624C1.91577 16.6336 1.88683 16.7127 1.87429 16.7953C1.86174 16.8778 1.86585 16.962 1.88638 17.0429C1.9069 17.1238 1.94341 17.1997 1.99377 17.2663C2.04413 17.3328 2.10731 17.3886 2.17958 17.4304C2.25185 17.4721 2.33175 17.499 2.41457 17.5093C2.49738 17.5197 2.58143 17.5134 2.66176 17.4907C2.74209 17.4681 2.81708 17.4296 2.88228 17.3775C2.94749 17.3254 3.00161 17.2608 3.04143 17.1874C4.51331 14.6437 7.11487 13.1249 10 13.1249C12.8852 13.1249 15.4867 14.6437 16.9586 17.1874C16.9985 17.2608 17.0526 17.3254 17.1178 17.3775C17.183 17.4296 17.258 17.4681 17.3383 17.4907C17.4186 17.5134 17.5027 17.5197 17.5855 17.5093C17.6683 17.499 17.7482 17.4721 17.8205 17.4304C17.8927 17.3886 17.9559 17.3328 18.0063 17.2663C18.0566 17.1997 18.0932 17.1238 18.1137 17.0429C18.1342 16.962 18.1383 16.8778 18.1258 16.7953C18.1132 16.7127 18.0843 16.6336 18.0407 16.5624ZM5.62503 7.49993C5.62503 6.63463 5.88162 5.78877 6.36235 5.06931C6.84308 4.34984 7.52636 3.78909 8.32579 3.45796C9.12522 3.12682 10.0049 3.04018 10.8535 3.20899C11.7022 3.3778 12.4818 3.79448 13.0936 4.40634C13.7055 5.01819 14.1222 5.79774 14.291 6.64641C14.4598 7.49508 14.3731 8.37474 14.042 9.17417C13.7109 9.9736 13.1501 10.6569 12.4306 11.1376C11.7112 11.6183 10.8653 11.8749 10 11.8749C8.84009 11.8737 7.72801 11.4124 6.90781 10.5922C6.0876 9.77195 5.62627 8.65987 5.62503 7.49993Z"
              fill="#28B14C"
            />
          </svg>
          <span
            class="hidden md:hidden lg:inline font-nunito bg-clip-text text-black px-2 cursor-pointer"
            @click="openLoginModal"
          >
            Login
          </span>

          <div
            v-if="showDropLogdown"
            class="absolute right-0 top-[calc(100%+10px)] w-48 lg:w-52 bg-white border rounded-lg shadow-lg z-50 text-[#28B14C] font-nunito font-normal md:leading-5 md:text-base"
          >
            <ul class="py-2">
              <li
                class="flex items-center px-4 py-2 text-black cursor-pointer"
                v-for="(item, index) in dropLogItem"
                :key="index"
                @click="navigateTo(item.route)"
              >
                <img
                  :src="item.icon"
                  :alt="item.alt"
                  class="md:w-5 md:h-5 mr-4"
                />
                <span class="text-black">{{ item.label }}</span>
              </li>
            </ul>
          </div>
        </div>

        <div class="relative lg:flex items-center hidden" @click="toggleHam">
          <a class="text-4xl cursor-pointer text-[#28B14C]">
            <img src="../../assets/List.png" class="h-6 w-6" />
          </a>
        </div>
      </div>

      <div
        v-if="showDropdown"
        class="absolute right-8 top-[calc(100%+1px)] w-48 lg:w-56 bg-white border rounded-lg navShadow z-50 text-[#28B14C] font-nunito font-normal md:leading-5 md:text-base"
      >
        <ul class="py-2">
          <li
            class="flex items-center px-5 py-2 hover:text-[#05AEEF] cursor-pointer"
            v-for="(item, index) in dropdownItems"
            :key="index"
            @click="item.action ? item.action() : navigateTo(item.route)"
          >
            <img :src="item.icon" :alt="item.alt" class="mr-4" />
            <span>{{ item.label }}</span>
          </li>
        </ul>
      </div>

      <!-- Mobile Menu Dropdown -->
      <div
        v-if="showMenu"
        class="absolute top-0 h-screen left-0 w-3/4 bg-white navShadow z-50 text-[#28B14C]"
      >
        <ul class="py-2">
          <li
            v-for="(item, index) in dropdownItemsMobile"
            :key="index"
            @click="item.action ? item.action() : navigateTo(item.route)"
            :class="[
              'flex items-center px-4 py-2 cursor-pointer',
              item.label.includes('Priyanshi')
                ? 'text-[#28B14C]  font-bold font-nunito'
                : 'hover:text-[#05AEEF]',
            ]"
          >
            <!-- Column 1: Profile Icon -->
            <div class="flex-shrink-0">
              <img
                :src="item.icon"
                :alt="item.alt"
                :class="
                  item.label.includes('Priyanshi')
                    ? 'w-10 h-10'
                    : 'md:w-5 md:h-5 mr-4'
                "
              />
            </div>

            <!-- Column 2: Name and Phone Number -->
            <div class="flex flex-col ml-4">
              <span>{{ item.label.split("\n")[0] }}</span>
              <span
                v-if="item.label.includes('Priyanshi')"
                class="text-sm text-[#28B14C] font-nunito font-normal"
              >
                {{ item.label.split("\n")[1] }}
              </span>
            </div>
          </li>
        </ul>
      </div>

      <LogOne v-if="showLoginModal" @close="showLoginModal = false" />
    </nav>
  </div>
</template>

<script>
import LogOne from "../LoginScreen/LogOne.vue";
import SearchBox from "./SearchBox.vue";

export default {
  name: "MainNavbar",
  components: {
    LogOne,
    SearchBox,
  },
  data() {
    return {
      navbarFixed: true,
      showDropdown: false,
      showDropLogdown: false,
      showLoginModal: false,
      showMenu: false, // State for mobile menu
      dropLogItem: [
        {
          label: `Priyanshi
9476380238`,
          route: "UserProfile",
          icon: require("@/assets/UserCircle.png"),
          alt: "Profile",
        },
        {
          label: "Logout",
          route: null,
          icon: require("@/assets/SignOut.png"),
          alt: "Logout",
        },
      ],

      dropdownItemsMobile: [
        {
          label: `Priyanshi
        9476380238`,
          route: "UserProfile",
          icon: require("@/assets/UserCircle1.png"),
          alt: "Profile",
        },
        {
          label: "Order History",
          route: "OrderPage",
          icon: require("@/assets/cart.png"),
          alt: "Orders",
        },
        {
          label: "Your Profile",
          route: "UserProfile",
          icon: require("@/assets/UserCircle.png"),
          alt: "Profile",
        },

        {
          label: "About Us",
          route: "AboutPage",
          icon: require("@/assets/UsersThree.png"),

          alt: "about",
        },
        {
          label: "Products",
          route: "Products",
          icon: require("@/assets/product.png"),
          alt: "about",
        },
        {
          label: "Referral",
          route: "ReferralPage",
          icon: require("@/assets/refer.png"),
          alt: "about",
        },
        {
          label: "Reviews",
          route: null,
          icon: require("@/assets/review.png"),
          alt: "review",
          action: () => this.scrollToSection("testimonials"),
        },
        {
          label: "Woo cash",
          route: "WooCashPage",
          icon: require("@/assets/Vector.png"),
          alt: "testimonial",
        },
        {
          label: "Manage Address",
          route: "AddressPage",
          icon: require("@/assets/map.png"),
          alt: "Address",
        },
        {
          label: "Contact Us",
          route: "ContactPage",
          icon: require("@/assets/call.png"),
          alt: "Help",
        },
      ],
      dropdownItems: [
        {
          label: "Your Profile",
          route: "UserProfile",
          icon: require("@/assets/UserCircle.png"),
          alt: "Profile",
        },
        {
          label: "Order History",
          route: "OrderPage",
          icon: require("@/assets/cart.png"),
          alt: "Orders",
        },
        {
          label: "Referral",
          route: "ReferralPage",
          icon: require("@/assets/refer.png"),
          alt: "about",
        },
        {
          label: "About Us",
          route: "AboutPage",
          icon: require("@/assets/UsersThree.png"),
          alt: "about",
        },
        {
          label: "Products",
          route: "Products",
          icon: require("@/assets/product.png"),
          alt: "about",
        },

        {
          label: "Reviews",
          route: null,
          icon: require("@/assets/review.png"),
          alt: "review",
          action: () => this.scrollToSection("testimonials"),
        },
        {
          label: "Woo cash",
          route: "WooCashPage",
          icon: require("@/assets/Vector.png"),
          alt: "woo",
        },
        // {
        //   label: "Testimonials",
        //   route: "AddressPage",
        //   icon: require("@/assets/testi.png"),
        //   alt: "testimonial",
        // },

        {
          label: "Manage Address",
          route: "AddressPage",
          icon: require("@/assets/map.png"),
          alt: "Address",
        },
        {
          label: "Contact Us",
          route: "ContactPage",
          icon: require("@/assets/call.png"),
          alt: "Help",
        },
      ],
    };
  },
  methods: {
    toggleHam() {
      this.showDropdown = !this.showDropdown;
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: "smooth" });
        this.showDropdown = false;
        this.showMenu = false;
      }
    },
    handleScroll() {
      this.navbarFixed = window.scrollY > 1;
    },
    navigateTo(route) {
      if (route) {
        this.$router.push({ name: route });
      }
      this.showDropdown = false;
      this.showMenu = false;
      this.showDropLogdown = false;
    },
    openLoginModal() {
      this.showLoginModal = true;
    },
    openCart() {
      this.$emit("toggle-cart");
    },
  },
};
</script>

<style>
.scrolled {
  position: fixed;
  top: 0;
}
.navShadow {
  box-shadow: 0px 10px 14px 0px rgba(74, 58, 255, 0.01),
    0px 9px 26px 0px rgba(23, 15, 73, 0.05);
}
</style>
