<template>
  <div class="md:mt-9 lg:mx-10 mx-5">
    <div class="md:p-4" v-if="product">
      <div class="flex lg:flex-row flex-col lg:mt-0 mt-16">
        <div
          class="flex justify-center border border-[#B8B8B8] rounded-xl md:py-9 md:mr-16 md:w-[570px] md:h-[580px] max-w-full"
        >
          <ProductImage :imgSrc="product.img" :images="product.images" />
        </div>

        <div class="flex-auto md:w-1/2 lg:mt-0 mt-6">
          <div class="flex lg:gap-2 flex-col md:flex-row">
            <h1 class="font-poppins md:text-2xl font-semibold leading-normal">
              <span class="font-poppins">{{ product.name }}</span>
            </h1>
            <div class="flex gap-5">
              <TotalRating />
              <TotalReview />
            </div>
          </div>

          <div class="mt-4 space-y-1">
            <h1 class="font-poppins md:text-sm font-medium">About this item</h1>
            <div
              v-for="(info, index) in product.additionalInfo"
              :key="index"
              class="flex items-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                class="mr-2"
              >
                <path
                  d="M14.354 4.85378L6.35403 12.8538C6.30759 12.9003 6.25245 12.9372 6.19175 12.9623C6.13105 12.9875 6.06599 13.0004 6.00028 13.0004C5.93457 13.0004 5.86951 12.9875 5.80881 12.9623C5.74811 12.9372 5.69296 12.9003 5.64653 12.8538L2.14653 9.35378C2.05271 9.25996 2 9.13272 2 9.00003C2 8.86735 2.05271 8.7401 2.14653 8.64628C2.24035 8.55246 2.3676 8.49976 2.50028 8.49976C2.63296 8.49976 2.76021 8.55246 2.85403 8.64628L6.00028 11.7932L13.6465 4.14628C13.7403 4.05246 13.8676 3.99976 14.0003 3.99976C14.133 3.99976 14.2602 4.05246 14.354 4.14628C14.4478 4.2401 14.5006 4.36735 14.5006 4.50003C14.5006 4.63272 14.4478 4.75996 14.354 4.85378Z"
                  fill="#28B14C"
                />
              </svg>
              <span
                class="md:text-sm text-xs text-gray-600 font-poppins font-normal"
                >{{ info }}</span
              >
            </div>
          </div>
          <hr
            class="md:my-4 bg-[#C9C9C9] border-0 h-[1px] mt-10 md:mt-3 mb-4 md:mb-3"
          />

          <p class="md:text-xl text-base font-bold font-poppins mb-3 md:mb-0">
            {{ product.price }}
            <span class="md:text-sm text-xs text-[#595959] font-normal ml-3"
              >Inclusive of all taxes</span
            >
          </p>

          <OrderBy />
          <ChoosePack />
          <SaveMorePack />
          <div class="flex gap-4 lg:gap-8">
            <AddToCartButton
              bgColor="bg-white"
              borderColor="border-green-500"
              textColor="text-green-500"
              @add-to-cart="addToCart"
              class="lg:w-56 w-full md:w-full"
            >
              <span class="font-nunito font-medium md:text-xl text-sm"
                >Add to Cart</span
              >
            </AddToCartButton>
            <AddToCartButton
              bgColor="bg-green-500"
              borderColor="border-green-500"
              textColor="text-white"
              @click="openCart"
              class="lg:w-56 w-full md:w-full"
            >
              <span class="font-nunito font-medium md:text-xl text-sm"
                >Buy Now</span
              >
            </AddToCartButton>
          </div>
          <div
            class="flex items-center font-medium space-x-4 text-[#777] font-poppins lg:text-sm text-xs my-5 md:my-0 lg:mt-6 lg:mb-5"
          >
            <span>Free shipping on all orders</span>
            <span
              class="border-l border-[#28B14C] md:h-4 h-6 md:mx-2 mx-1"
            ></span>
            <span>Country of origin: India</span>
          </div>

          <CartSidebar
            v-if="isCartVisible"
            :isCartVisible="isCartVisible"
            :cartItems="cartItems"
            @increase-quantity="increaseQuantity"
            @decrease-quantity="decreaseQuantity"
            @remove-item="removeItem"
            @close-cart="closeCart"
          />
          <CashBack />
          <DeliveryEstimate />
          <ProductAccordian />
          <DisposeAccordian />
        </div>
      </div>
    </div>
  </div>
  <div>
    <ChooseUs />
    <KnowAboutOurPad />
    <SustanabilityCertification />

    <BestsellerProduct />
    <OurCustomer />
  </div>
</template>

<script>
import { products } from "../../productData";
import OrderBy from "@/components/ProductDetail/OrderBy.vue";
import ChoosePack from "./ChoosePack.vue";
import SaveMorePack from "./SaveMorePack.vue";
import AddToCartButton from "../Buttons/AddCart.vue";
import CashBack from "./CashBack.vue";
import DeliveryEstimate from "./DeliveryEstimate.vue";
import KnowAboutOurPad from "./KnowAboutOurPad.vue";
import ProductAccordian from "./ProductAccordian.vue";
import DisposeAccordian from "./DisposeAccordian.vue";
import ChooseUs from "../ChooseUs/ChooseUs.vue";
import BestsellerProduct from "../BestsellerProduct/BestsellerProduct.vue";
import OurCustomer from "../OurCustomer/OurCustomer.vue";
import SustanabilityCertification from "./SustanabilityCertification.vue";
import ProductImage from "./ProductImage.vue";
import { mapState, mapActions } from "vuex";
import { products as localProducts } from "../../productData"; // Import local products
import TotalRating from "./TotalRating.vue";
import TotalReview from "./TotalReview.vue";
export default {
  name: "ProductDetail",
  components: {
    OrderBy,
    ChoosePack,
    SaveMorePack,
    AddToCartButton,
    CashBack,
    DeliveryEstimate,
    ProductAccordian,
    DisposeAccordian,
    KnowAboutOurPad,
    SustanabilityCertification,
    ChooseUs,
    BestsellerProduct,
    OurCustomer,
    ProductImage,
    TotalRating,
    TotalReview,
  },

  created() {
    const productId = Number(this.$route.params.id);
    this.fetchProduct(productId);
  },
  methods: {
    fetchProduct(productId) {
      this.product = products.find((product) => product.id === productId);
      if (!this.product) {
        console.error("Product not found");
      }
    },
    ...mapActions([
      "addToCart",
      "increaseQuantity",
      "decreaseQuantity",
      "removeItem",
      "closeCart",
    ]),
    goToAllProducts() {
      this.$router.push("/products");
    },
    handleAddToCart(product) {
      console.log("Adding to cart:", product);
      this.addToCart(product);
      this.closeCart();
    },
    // handleAddToCart() {
    //   this.$emit("add-to-cart", this.product);
    // },
    // toggleCart() {
    //   this.isCartVisible = !this.isCartVisible;
    // },
  },
  computed: {
    ...mapState(["cartItems", "isCartVisible"]),
  },
  data() {
    return {
      localProducts,
    };
  },
};
</script>
