<template>
  <div>
    <MainSlider />
    <MainFeature />

    <div
      class="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-4 my-10 lg:gap-3 md:gap-x-5 gap-x-3 gap-y-10 lg:mx-24 mx-4 md:mx-8 md:my-16 cursor-pointer"
    >
      <ProductCard
        v-for="product in products"
        :key="product.id"
        :product="product"
        @add-to-cart="addToCart"
        class="cursor-pointer"
      />
    </div>
    <OurCustomer />
    <CartSidebar
      v-if="isCartVisible"
      :isCartVisible="isCartVisible"
      :cartItems="cartItems"
      @increase-quantity="increaseQuantity"
      @decrease-quantity="decreaseQuantity"
      @remove-item="removeItem"
      @close-cart="closeCart"
    />
  </div>
</template>

<script>
import MainFeature from "../MainFeature/MainFeature.vue";
import MainSlider from "../Slider/MainSlider.vue";
import { products } from "../../productData";
import ProductCard from "./ProductCard.vue";
import CartSidebar from "../BestsellerProduct/CartSidebar.vue";
import { mapState, mapActions } from "vuex";
import OurCustomer from "../OurCustomer/OurCustomer.vue";

export default {
  name: "AllProducts",
  components: {
    MainSlider,
    MainFeature,
    ProductCard,
    CartSidebar,
    OurCustomer,
  },
  data() {
    return {
      products,
    };
  },
  computed: {
    ...mapState(["cartItems", "isCartVisible"]), // Map state from Vuex store
  },
  methods: {
    ...mapActions([
      "addToCart",
      "increaseQuantity",
      "decreaseQuantity",
      "removeItem",
      "closeCart",
    ]),

    handleAddToCart(product) {
      this.addToCart(product);
      this.closeCart(); // Optionally close cart after adding
    },
  },
};
</script>
