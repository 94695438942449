<template>
  <BaseLayout>
    <OrderHistory />
  </BaseLayout>
</template>

<script>
import { defineComponent } from "vue";
import BaseLayout from "./BaseLayout.vue";
import OrderHistory from "./OrderHistory.vue";

export default defineComponent({
  components: {
    BaseLayout,
    OrderHistory,
  },
});
</script>
