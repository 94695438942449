<template>
  <div
    class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
  >
    <div class="bg-white rounded-lg p-6 relative w-12/12 md:w-1/2 lg:w-1/3">
      <!-- Close Button -->
      <button
        @click="$emit('close')"
        class="absolute top-4 right-4 text-black hover:text-red-500"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
      <div class="flex justify-center">
        <img
          :src="product.img"
          alt="Product image"
          class="md:w-70 object-cover rounded-lg md:h-auto mb-4"
        />
      </div>

      <div class="flex justify-between">
        <h3 class="lg:text-lg font-semibold text-gray-800 mb-2">
          {{ product.name }}
        </h3>
        <p class="text-base font-medium text-custom-blue font-poppins mb-2">
          {{ product.price }}
        </p>
      </div>

      <p
        class="text-[#000] text-[12px] font-normal leading-[19.643px] mb-2 font-roboto"
      >
        {{ product.description }}
      </p>

      <!-- Additional Information -->
      <div
        v-for="(info, index) in product.additionalInfo"
        :key="index"
        class="text-sm text-[#28B14C] font-normal mb-1"
      >
        - {{ info }}
      </div>
      <div class="border bg lg:mt-4 rounded-[10px]">
        <p class="text-sm text-[#595959] italic md:mt-2 mb-4 px-2 font-normal">
          <span class="text-[#28B14C]">How it Works: </span>
          {{ product.howItWorks.description }}
        </p>
      </div>

      <!-- Add to Cart Button -->
      <button
        @click="handleAddToCart(product)"
        class="bg-custom-blue text-white px-3 py-2 mt-5 rounded-lg font-poppins font-medium text-[12px] w-full"
      >
        Add to Cart
      </button>

      <CartSidebar
        v-if="isCartVisible"
        :isCartVisible="isCartVisible"
        :cartItems="cartItems"
        @increase-quantity="increaseQuantity"
        @decrease-quantity="decreaseQuantity"
        @remove-item="removeItem"
        @close-cart="closeCart"
      />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { products as localProducts } from "../../productData"; // Import local products
import CartSidebar from "../BestsellerProduct/CartSidebar.vue";
export default {
  components: {
    CartSidebar,
  },
  props: {
    product: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(["cartItems", "isCartVisible"]),
  },
  data() {
    return {
      localProducts,
    };
  },
  methods: {
    ...mapActions([
      "addToCart",
      "increaseQuantity",
      "decreaseQuantity",
      "removeItem",
      "closeCart",
    ]),
    goToAllProducts() {
      this.$router.push("/products");
    },
    handleAddToCart(product) {
      console.log("Adding to cart:", product);
      this.addToCart(product);
    },
  },
};
</script>
<style>
.bg {
  background: rgba(40, 177, 76, 0.14);
}
</style>
