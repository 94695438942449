<template>
  <div
    class="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
  >
    <div
      class="bg-white md:p-6 rounded-lg shadow-lg w-full max-w-md relative max-h-screen overflow-y-auto"
    >
      <!-- Close Icon -->
      <button
        @click="$emit('close')"
        class="absolute top-4 right-4 text-red-500 text-4xl"
      >
        &times;
      </button>

      <div class="flex flex-col items-center">
        <!-- "Get Started Now" Text -->
        <h1
          class="text-3xl text-gray-800 mb-6 font-poppins font-medium text-left"
        >
          Get Started Now
        </h1>

        <!-- Name Field -->
        <label
          for="firstName"
          class="w-2/3 text-black mb-2 font-nunito font-bold text-base"
          >First Name</label
        >
        <input
          id="firstName"
          type="text"
          placeholder="Enter your first name"
          class="w-2/3 p-3 border rounded-[10px] mb-4 focus:outline-none"
        />

        <!-- Last Name Field -->
        <label
          for="lastName"
          class="w-2/3 text-black mb-2 font-nunito font-bold text-base"
          >Last Name</label
        >
        <input
          id="lastName"
          type="text"
          placeholder="Enter your last name"
          class="w-2/3 p-3 border rounded-md mb-4 focus:outline-none"
        />

        <!-- Email Address Field -->
        <label
          for="email"
          class="w-2/3 mb-2 font-nunito font-bold text-base text-black"
          >Email Address</label
        >
        <input
          id="email"
          type="email"
          placeholder="Enter your email address"
          class="w-2/3 p-3 border rounded-md mb-4 focus:outline-none"
        />

        <!-- Checkbox -->
        <div class="flex items-center w-2/3 mb-6">
          <input
            type="checkbox"
            id="terms"
            class="mr-2 h-4 w-4 border-gray-300 rounded"
          />
          <label for="terms" class="text-gray-600"
            >I agree to the terms & policy</label
          >
        </div>

        <button
          @click="$emit('openLogThree')"
          class="w-2/3 p-3 text-white rounded-md mb-4 bg-[#28B14C] font-poppins font-medium"
        >
          Sign Up
        </button>

        <!-- "or" Text -->
        <p class="text-black mb-4 font-poppins">or</p>

        <!-- Sign in with Google Button -->
        <button
          class="flex items-center justify-center w-2/3 p-3 border rounded-xl text-black font-nunito mb-6"
        >
          <img
            src="@/assets/google.png"
            alt="Google Icon"
            class="w-6 h-6 mr-2"
          />
          Sign in with Google
        </button>

        <!-- Already have an account? Sign In -->
        <p class="text-black text-lg font-bold font-inter">
          Have an account?
          <a
            href="#"
            class="text-[#114B20] text-lg font-bold font-inter hover:underline"
            >Sign In</a
          >
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "LogTwo",
};
</script>
