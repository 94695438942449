<template>
  <BaseLayout>
    <EditProfile />
  </BaseLayout>
</template>

<script>
import { defineComponent } from "vue";
import BaseLayout from "./BaseLayout.vue";
import EditProfile from "./EditProfile.vue";

export default defineComponent({
  components: {
    BaseLayout,
    EditProfile,
  },
});
</script>
