<template>
  <div class="">
    <BaseLayout>
      <div class="flex flex-col w-full mx-3 md:mx-0">
        <h2
          class="md:text-3xl text-lg font-medium leading-normal md:mb-6 text-left"
        >
          Woo Cash
        </h2>
        <div class="md:bg-[#FCFBF7] shadow radius">
          <MaxCashBackValue class="md:hidden" />

          <TotalWooCash />
          <CashBackHistory />
          <MaxCashBackValue class="hidden" />
        </div>
      </div>
      <!-- <ShippingInfo /> -->
    </BaseLayout>
  </div>
</template>

<script>
// import ShippingInfo from "../UserProfile/ShippingInfo.vue";
import CashBackHistory from "./CashBackHistory.vue";
import TotalWooCash from "./TotalWooCash.vue";

import MaxCashBackValue from "./MaxCashBackValue.vue";
import BaseLayout from "../UserProfile/BaseLayout.vue";
export default {
  name: "WooCashPage",
  components: {
    TotalWooCash,
    // ShippingInfo,
    MaxCashBackValue,
    CashBackHistory,
    BaseLayout,
  },
};
</script>
