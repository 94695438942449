<!-- ReturnCalPage.vue -->
<template>
  <div class="lg:mt-20 md:mt-16 mt-10 mx-4 lg:mx-24 md:space-y-10">
    <ReturnPolicy />
    <CancelPolicy />
  </div>
</template>

<script>
import CancelPolicy from "./CancelPolicy.vue";
import ReturnPolicy from "./ReturnPolicy.vue";

export default {
  components: {
    ReturnPolicy,
    CancelPolicy,
  },
};
</script>
