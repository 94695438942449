<template>
  <div class="lg:mx-24 mx-4 md:mx-8 lg:px-0 md:my-10 my-8">
    <div
      class="bg-white shadow-lg rounded-lg border border-[#28B14C] lg:px-24 md:px-12 px-4 py-8 md:py-10"
    >
      <div
        class="grid md:grid-cols-2 grid-cols-2 sm:grid-cols-2 lg:grid-cols-2 lg:gap-y-16 gap-x-4 md:gap-x-11 gap-y-6"
      >
        <!-- Loop through the features array -->
        <div
          v-for="(feature, index) in features"
          :key="index"
          class="flex md:items-start items-center flex-col md:flex-row md:space-x-4"
        >
          <img
            :src="feature.icon"
            alt="Feature Icon"
            class="md:w-20 md:h-20 w-10 h-10 mb-3 lg:mb-0"
          />
          <div>
            <h2
              class="md:text-xl text-xs font-semibold font-inter lg:text-lg md:mb-4 text-left md:text-left"
            >
              {{ feature.title }}
            </h2>
            <p
              class="mt-4 text-black md:text-base font-normal hidden lg:inline font-nunito"
            >
              {{ feature.description }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import privacyIcon from "@/assets/featureOne.png";
import satisfactionIcon from "@/assets/featureTwo.png";
import ecoIcon from "@/assets/featureThree.png";
import dermatologyIcon from "@/assets/featureFour.png";

export default {
  name: "ProductFeature",
  data() {
    return {
      features: [
        {
          title: "Discreet and Secured",
          description:
            "Our pads come in unmarked, eco-friendly packaging, ensuring your privacy and delivering peace of mind with every purchase.",
          icon: privacyIcon,
        },
        {
          title: "Eco-Friendly ",
          description:
            "We stand behind our products with a satisfaction guarantee. Your comfort and satisfaction are our top priorities.",
          icon: satisfactionIcon,
        },
        {
          title: "Peace of Mind",
          description:
            "Our pads are made from biodegradable materials, contributing to a healthier planet without compromising quality.",
          icon: dermatologyIcon,
        },
        {
          title: "Your Needs Matter",
          description:
            "Our products are dermatologically tested to ensure safety and comfort for all skin types.",
          icon: ecoIcon,
        },
      ],
    };
  },
};
</script>
