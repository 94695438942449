<template>
  <div class="w-full lg:w-2/4 md:p-4 mt-6 md:mt-0 my-6">
    <form
      @submit.prevent="handleSubmit"
      class="form-container space-y-9 md:p-12 p-4 py-7 lg:my-12"
    >
      <div class="flex space-x-4">
        <input
          type="text"
          v-model="formData.firstName"
          class="w-1/2 input-field text-base"
          placeholder="First Name"
          required
        />
        <input
          type="text"
          v-model="formData.lastName"
          class="w-1/2 input-field text-base"
          placeholder="Last Name"
          required
        />
      </div>

      <div>
        <input
          type="email"
          v-model="formData.email"
          class="w-full input-field text-base"
          placeholder="Your Email"
          required
        />
      </div>

      <div>
        <input
          type="tel"
          v-model="formData.phone"
          class="w-full input-field text-base"
          placeholder="Phone Number"
          required
        />
      </div>

      <!-- Message Textarea (full width) -->
      <div>
        <textarea
          v-model="formData.message"
          class="w-full input-field text-base"
          placeholder="Your Message"
          rows="4"
          required
        ></textarea>
      </div>

      <button
        type="submit"
        class="w-full bg-[#28B14C] text-white md:py-3 py-2 rounded font-poppins text-xl font-medium leading-normal"
      >
        Send Message
      </button>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      formData: {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: "",
      },
    };
  },
  methods: {
    handleSubmit() {
      // Handle form submission logic here
      console.log(this.formData);
      // Reset form if necessary
      this.formData = {
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        message: "",
      };
    },
  },
};
</script>

<style scoped>
.form-container {
  border-radius: 10px;
  border: 1px solid #28b14c;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.1);
}

.input-field {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid #828282;
  background: #f9f9f9;
}

button {
  border-radius: 10px;
}
</style>
