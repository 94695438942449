<template>
  <div class="max-w-7xl lg:mx-24 mx-4 md:mx-8 md:mt-10 my-10 md:my-10">
    <div class="flex justify-between items-center lg:mb-12 mb-5">
      <div>
        <h2
          class="text-2xl md:text-3xl lg:text-4xl font-bold text-black font-nunito md:mb-2"
        >
          Bestseller
        </h2>
        <p class="text-[#6B6B6B] text-base md:text-[18px] lg:text-lg">
          Shop our most loved products
        </p>
      </div>
      <button
        @click="goToAllProducts"
        class="border border-custom-blue font-nunito w-32 lg:w-48 text-custom-blue px-4 py-2 font-bold rounded text-sm lg:text-base"
      >
        View all <span class="hidden md:inline">products</span>
      </button>
    </div>

    <div
      class="grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 lg:gap-10 md:gap-x-5 gap-x-3 gap-y-10 cursor-pointer"
    >
      <ProductCard
        v-for="product in (products || localProducts).slice(0, 4)"
        :key="product.id"
        :product="product"
        @add-to-cart="addToCart"
        class="cursor-pointer"
      />
    </div>

    <CartSidebar
      v-if="isCartVisible"
      :isCartVisible="isCartVisible"
      :cartItems="cartItems"
      @increase-quantity="increaseQuantity"
      @decrease-quantity="decreaseQuantity"
      @remove-item="removeItem"
      @close-cart="closeCart"
    />

    <p
      v-if="!(products || localProducts).length"
      class="text-center text-gray-500 mt-4"
    >
      No products available.
    </p>
  </div>
</template>

<script>
import ProductCard from "../AllProducts/ProductCard.vue";
import { mapState, mapActions } from "vuex";
import { products as localProducts } from "../../productData"; // Import local products
import CartSidebar from "./CartSidebar.vue";

export default {
  components: {
    ProductCard,
    CartSidebar,
  },
  computed: {
    ...mapState(["cartItems", "isCartVisible"]),
  },
  data() {
    return {
      localProducts,
    };
  },
  methods: {
    ...mapActions([
      "addToCart",
      "increaseQuantity",
      "decreaseQuantity",
      "removeItem",
      "closeCart",
    ]),
    goToAllProducts() {
      this.$router.push("/products");
    },
    handleAddToCart(product) {
      console.log("Adding to cart:", product);
      this.addToCart(product);
      this.closeCart();
    },
  },
};
</script>
