<template>
  <section class="py-12 px-4 md:px-8 lg:px-24">
    <div class="max-w-7xl mx-auto">
      <div class="bg-white rounded-lg lg:p-4">
        <img
          :src="blog.secondaryImage"
          alt="Blog Image"
          class="w-full md:h-96 object-cover mb-4"
        />
        <div
          class="md:font-medium md:px-3 md:py-1 mb-1 md:w-full md:flex md:text-base font-nunito md:leading-relaxed md:mb-8"
        >
          <span class="text-[#28B14C] font-bold uppercase md:mr-6">
            {{ blog.category }}</span
          >
          <p class="text-[#363D41] font-medium capitalize">
            {{ blog.datePosted }}
          </p>
        </div>
        <h3
          class="md:text-5xl md:leading-10 font-bold text-[#333] md:mt-3 font-nunito"
        >
          {{ blog.title }}
        </h3>
        <div
          class="md:mt-12 text-[#666] md:text-base md:leading-relaxed font-normal font-nunito"
        >
          <p v-html="blog.blogContent"></p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { blogs } from "./blogData";

export default {
  data() {
    return {
      blog: {},
    };
  },
  created() {
    const blogId = this.$route.params.id;
    this.blog = blogs[blogId]; // Assuming blogId is the index of the blog
  },
};
</script>

<style scoped>
/* Add any specific styles here */
</style>
