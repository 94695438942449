<template>
  <div class="lg:h-[340px] md:h-72 h-max p-8 md:p-0">
    <div class="relative flex justify-center">
      <img
        :src="selectedImage"
        alt="Product Image"
        class="md:w-[350px] md:h-[340px] md w-60 h-60"
      />
      <button class="absolute md:top-2 md:right-2 top-1 right-[-2rem] p-2">
        <img
          src="@/assets/shareprod.png"
          alt="Share Icon"
          class="lg:w-8 w-6 h-6 lg:h-8"
        />
      </button>
    </div>

    <div
      class="md:grid hidden grid-cols-4 md:grid-cols-auto-fit md:gap-6 mt-10 w-full"
    >
      <img
        v-for="(image, index) in images"
        :key="index"
        :src="image"
        alt="Product Thumbnail"
        @click="selectImage(index)"
        :class="{
          'border-green-500': selectedIndex === index,
          'border-[#D5D5D5]': selectedIndex !== index,
        }"
        class="md:w-24 md:h-24 md:p-3 object-cover border rounded-xl cursor-pointer"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "ProductImage",
  props: {
    imgSrc: {
      type: String,
      required: true,
    },
    images: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedIndex: 0,
    };
  },
  computed: {
    selectedImage() {
      return this.images[this.selectedIndex];
    },
  },
  methods: {
    selectImage(index) {
      this.selectedIndex = index;
    },
  },
};
</script>
